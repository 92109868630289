import { useBaseOneActions } from '../base.actionsOne';
import { nivelPosicaoAtom } from './NivelPosicaoState';

function useNivelPosicaoActions() {
  const baseActions = useBaseOneActions('nivelPosicao', nivelPosicaoAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useNivelPosicaoActions };
