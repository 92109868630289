import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer } from '../../commons/styleds/styleds';
import { Link, useLocation, useParams } from 'react-router-dom';
import { history } from '../../helpers/history';
import { useMenusActions } from '../../states/menus/menu.actions';
import { IArea, IConteudo, IMenu, ISecao } from '../../commons/types/base';
import { Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { findBreadcrumb } from '../../components/Breadcrumb/findBreadcrumb';
import type { IMenuItens } from '../../components/Header';
import Eventos from '../Eventos';

function createHTMLFromString(htmlString: any) {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

interface IParams {
  id?: string;
}

const SecaoComponent: React.FC = () => {
  const path = window.location.pathname;
  const { id } = useParams<IParams>();
  const [conteudos, setConteudos] = useState<IConteudo[]>([]);
  const [secao, setSecao] = useState<ISecao>();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isEvento, setIsEvento] = useState<boolean>(false);

  const menusActions = useMenusActions();

  useEffect(() => {
    const loadData = async () => {
      let structure = JSON.parse(localStorage.getItem('menusDinamicos') ?? '[]') as IMenuItens[];
      const breadcrumb = findBreadcrumb(currentPath, structure);
      const indexEventos = breadcrumb!.findIndex((f: any) => f.nome === 'Eventos');
      if (indexEventos >= 0 && indexEventos < breadcrumb!.length - 1) {
        setIsEvento(true);
      }
    }
    loadData();
    menusActions
      .get()
      .then((d) => {
        let menus: IMenu = d.resultado;

        const secao = menus.secoes.find((f) => f.id == Number(id));
        const area = menus.areas.find((f) => f.id == secao?.idArea!);
        const conteudos = menus.conteudos.filter(
          (f) =>
            f.idSecaos!.findIndex((f) => f.id == Number(id)) >= 0 &&
            f.idAreas!.findIndex((f) => f.id == area?.id) >= 0,
        );
        setSecao(secao);
        setConteudos(conteudos);
      })
      .catch((erro) => {
      });
  }, [id]);

  return (
    <>
      {isEvento ? <Eventos /> :
        <GridContainer
          container
          direction="row"
          spacing={0}
          sx={{
            display: 'flex',
            padding: '10px 15px 10px 10px',
            margin: '0px 30px !important',
            backgroundColor: 'white',
          }}
        >
          <Grid
            container
            direction="row"
            spacing={0}
            sx={{ paddingTop: '25px', display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
            xs={12}
            md={12}
          >
            <Grid
              container
              xs={12}
              md={12}
              sm={12}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              <Grid xs={12} md={12} sm={12} sx={{ borderBottom: '2.5px solid rgb(255 191 0)' }}>
                <Typography sx={{ padding: '10px 0px' }} variant="h4" component="h2">
                  {secao?.nome}
                </Typography>
              </Grid>
              <Grid
                container
                xs={12}
                md={12}
                sx={{ paddingBottom: '10px', display: 'flex', borderBottom: 'solid 1.5px #dfdfdf' }}
              >
                <Typography sx={{ padding: '10px' }} variant="caption">
                  {createHTMLFromString(secao?.descricao)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
              {conteudos.map((item: IConteudo) => {
                return (
                  <>
                    <Grid container xs={12} md={12} sx={{ paddingBottom: '10px', display: 'flex' }}>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        sx={{
                          paddingBottom: '10px',
                          display: 'flex',
                          borderBottom: 'solid 1.5px #dfdfdf',
                        }}
                      >
                        {item.anexo && (
                          <>
                            <Grid
                              container
                              xs={2}
                              md={2}
                              sx={{ display: 'flex', padding: '20px 10px' }}
                            >
                              <img
                                src={item.anexo}
                                alt="imagem"
                                width={'100%'}
                                style={{ maxWidth: '200px' }}
                              />
                            </Grid>
                          </>
                        )}

                        <Grid container xs={10} md={10} sx={{ display: 'flex', padding: '20px 10px' }}>
                          <Link to={path + '/conteudos/' + item.id} style={{ cursor: 'pointer' }}>
                            {' '}
                            {item.titulo}{' '}
                          </Link>
                          {item.dtPublicacao && (
                            <Grid container xs={12} md={12} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                              <IconButton
                                sx={{
                                  display: 'flex',
                                  alignContent: 'center',
                                  flexWrap: 'wrap',
                                }}
                              >
                                <CalendarMonthIcon
                                  sx={{
                                    fontSize: '16px',
                                    color: '#551a8b',
                                  }}
                                />
                              </IconButton>
                              <span
                                style={{
                                  display: 'flex',
                                  alignContent: 'center',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {new Date(item.dtPublicacao)?.toLocaleDateString()}
                              </span>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Grid>
        </GridContainer>
      }
    </>
  );
};

export default SecaoComponent;
