import { Grid2, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomTabPanel } from "../../DadosPessoais/accordionAndTabPanel";
import { useEventosActions } from "../../../states/eventos/eventos.actions";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import type { IEvento } from "../../../commons/types/eventos";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { authAtom } from "../../../states/usuarios/AuthState";
import { useRecoilValue } from "recoil";
import ProximosEventos from "./ProximosEventos";

dayjs.extend(customParseFormat);

interface IEventosQuery {
  tipo: string;
  dataInicio: Date | Dayjs | null;
  dataFim: Date | Dayjs | null;
  apenasAtivos: boolean;
}

export interface IEventoPage {
  tipo: string;
}

const EventosExternos: React.FC<IEventoPage> = ({ tipo }) => {
  const auth = useRecoilValue(authAtom);
  const [selectedTab, setSelectedTab] = useState(0);
  const actions = useEventosActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [eventos, setEventos] = useState<IEvento[]>([]);
  const [eventosQuery, setEventosQuery] = useState<IEventosQuery>({
    tipo: tipo,
    dataInicio: null,
    dataFim: null,
    apenasAtivos: true,
  });

  const loadData = async () => {
    setLoading(true);
    const resp = await actions.list(false, makeQueryParams());
    setEventos(resp);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleChangeTab = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDateChange = (field: 'dataInicio' | 'dataFim', value: dayjs.Dayjs | null) => {
    setEventosQuery(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const makeQueryParams = () => {
    const queryParams = new URLSearchParams();
    queryParams.set('tipo', eventosQuery.tipo);
    if (eventosQuery.dataInicio) {
      queryParams.set('dataInicio', eventosQuery.dataInicio.toISOString());
    }
    if (eventosQuery.dataFim) {
      queryParams.set('dataFim', eventosQuery.dataFim.toISOString());
    }
    return `?${queryParams.toString()}`;
  }

  const handleFilterEvents = async () => {
    loadData();
  };

  return (
    <Grid2 container>
      <Grid2 size={{ xs: 12 }}>
        <div>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Próximos eventos" />
          </Tabs>
        </div>
      </Grid2>

      <Grid2>
        <CustomTabPanel value={selectedTab} index={0}>
          <ProximosEventos
            eventos={eventos}
            eventosQuery={eventosQuery}
            loading={loading}
            handleDateChange={handleDateChange}
            handleFilterEvents={handleFilterEvents}
          />
        </CustomTabPanel>
      </Grid2>
    </Grid2>
  );
};

export default EventosExternos;