import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Paper, Button, Typography, LinearProgress, Grid2, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RecordVoiceOverRoundedIcon from '@mui/icons-material/RecordVoiceOverRounded';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { IEvento, IEventoParticipante } from '../../../../commons/types/eventos';
import styled from 'styled-components';
import { createHTMLFromString } from '../../../PaginasDinamicas';

interface EventosInscritosProps {
  eventosInscritos: IEventoParticipante[];
  eventosQuery: { dataInicio: Date | Dayjs | null; dataFim: Date | Dayjs | null; };
  loading: boolean;
  handleDateChange: (field: 'dataInicio' | 'dataFim', value: Dayjs | null) => void;
  handleFilterEvents: () => void;
  inscrever: (id: number) => void;
}

const EventosInscritos: React.FC<EventosInscritosProps> = ({
  eventosInscritos, eventosQuery, loading, handleDateChange, handleFilterEvents, inscrever
}) => {
  const getMinDataFim = () => {
    return eventosQuery.dataInicio ? dayjs(eventosQuery.dataInicio) : dayjs();
  };

  const getDataEvento = (evento: IEvento) => {
    return `${dayjs(evento.dtInicio).format('DD/MM/YYYY HH:mm')} - ${dayjs(evento.dtFim).format('HH:mm')}`;
  }

  return (
    <Grid2 container spacing={2} alignItems="center">
      <Grid2 container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <DesktopDatePicker
              value={eventosQuery.dataInicio ? dayjs(eventosQuery.dataInicio) : null}
              onChange={(newValue) => handleDateChange('dataInicio', newValue)}
              label="Data Início"
              format="DD/MM/YYYY"
              minDate={dayjs()}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <DesktopDatePicker
              value={eventosQuery.dataFim ? dayjs(eventosQuery.dataFim) : null}
              onChange={(newValue) => handleDateChange('dataFim', newValue)}
              label="Data Fim"
              format="DD/MM/YYYY"
              minDate={getMinDataFim()}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 4 }}>
            <ButtonFilter onClick={handleFilterEvents}>Filtrar</ButtonFilter>
          </Grid2>
        </LocalizationProvider>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        {loading
          ? (<>
            <LinearProgress sx={{ width: '100%' }} />
          </>)
          : eventosInscritos.length == 0
            ? (<Typography variant="body1">Nenhum evento encontrado.</Typography>)
            : (
              <Grid2 container spacing={1}>
                {eventosInscritos.map((eventoInscrito: IEventoParticipante) => (
                  <Grid2 size={{ xs: 12, lg: 8 }}>
                    <Accordion key={eventoInscrito.idEventoNavigation!.id} sx={{ width: '100%' }}>
                      <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6">{eventoInscrito.idEventoNavigation!.titulo}</Typography>
                            {dayjs(eventoInscrito.idEventoNavigation!.dtInicio).isBefore(dayjs()) && (
                              <Tooltip title="Inscrições já encerradas" placement="top">
                                <WarningOutlinedIcon color="warning" />
                              </Tooltip>
                            )}
                          </div>
                          <Grid2 container spacing={1}>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }}>
                              <CalendarMonthRoundedIcon fontSize="small" />
                              <Typography variant="body2">{getDataEvento(eventoInscrito.idEventoNavigation!)}</Typography>
                            </Grid2>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }}>
                              <LocationOnRoundedIcon fontSize="small" />
                              <Typography variant="body2">{eventoInscrito.idEventoNavigation!.modoEvento === 'Online' ? 'Online' : 'Presencial'}</Typography>
                            </Grid2>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }}>
                              <RecordVoiceOverRoundedIcon />
                              <Typography variant="body2">Palestrante: {eventoInscrito.idEventoNavigation!.palestrante}</Typography>
                            </Grid2>
                          </Grid2>
                        </div>
                      </AccordionSummaryStyled>
                      <AccordionDetails>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
                          <Grid2 container spacing={1}>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }} size={{ xs: 12 }}>
                              <Typography variant="h6" sx={{ width: '100%' }}>{eventoInscrito.idEventoNavigation!.titulo}</Typography>
                            </Grid2>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }} size={{ xs: 12 }}>
                              <CalendarMonthRoundedIcon fontSize="small" />
                              <Typography variant="body2">{getDataEvento(eventoInscrito.idEventoNavigation!)}</Typography>
                            </Grid2>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }} size={{ xs: 12 }}>
                              <LocationOnRoundedIcon fontSize="small" />
                              <Typography variant="body2">{eventoInscrito.idEventoNavigation!.modoEvento === 'Online' ? 'Online' : 'Presencial'}</Typography>
                            </Grid2>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }} size={{ xs: 12 }}>
                              <RecordVoiceOverRoundedIcon />
                              <Typography variant="body2">Palestrante: {eventoInscrito.idEventoNavigation!.palestrante}</Typography>
                            </Grid2>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }} size={{ xs: 12 }}>
                              <CalendarMonthRoundedIcon fontSize="small" />
                              <Typography variant="body2">Inscrito em: {dayjs(eventoInscrito.dtCadastro)?.format('DD/MM/YYYY HH:mm')}</Typography>
                            </Grid2>
                            <Grid2 sx={{ display: 'flex', alignItems: 'center' }} size={{ xs: 12 }}>
                              <div>
                                <Typography variant="body1">Descrição:</Typography>
                                {createHTMLFromString(eventoInscrito.idEventoNavigation!.descricao)}
                              </div>
                            </Grid2>
                          </Grid2>
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  </Grid2>
                ))}
              </Grid2>
            )
        }
      </Grid2>
    </Grid2>
  );
};

const ButtonFilter = styled(Button)`
  color: #414651 !important;
  border-color: #d5d7da !important;
  text-transform: none !important;
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
`;


export default EventosInscritos;
