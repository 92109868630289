import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { styled } from 'styled-components';
import React from 'react';
import { authAtom } from '../../states/usuarios/AuthState';
import LinearProgress from '@mui/material/LinearProgress';
import { Divider, Paper, Stack, Box, Tab, Tabs } from '@mui/material';
import { IComandaEtapaReunioes, ICv, IEtapa } from '../../commons/types/crm';
import Etapa from './components/Etapas';
import { GridBackground } from '../Acoes';
import Consultor from '../Home/Components/Consultor';
import Breadcrumb from '../../components/Breadcrumb';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import CircularProgressWithMultipleLabels from '../../components/CircularProgressWithMultipleLabels';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useComandasActions } from '../../states/comandas/comandas.actions';
import { toast } from 'react-toastify';
import { useAnexosActions } from '../../states/anexos/anexos.actions';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  padding: 5,
  borderRadius: 5,
}));

const progressValues = [
  { value: 80, color: '#003f88' },
  { value: 60, color: '#007bff' },
  { value: 40, color: '#00b4d8' },
];

const Programa: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const comandasActions = useComandasActions();
  const anexosActions = useAnexosActions();
  const [expanded, setExpanded] = useState<number>(-1);
  const [porcentagem, setPorcentagem] = useState<number>(0);
  const [tipo, setTipo] = useState<string>('THINK');
  const [reunioes, setReunioes] = useState<IComandaEtapaReunioes[]>([]);
  const [etapas, setEtapas] = useState<IEtapa[]>([]);
  const [anexos, setAnexos] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [numeroEtapasCompletas, setNumeroEtapasCompletas] = useState(0);
  const [progressoPorTipo, setProgressoPorTipo] = useState<any[]>(progressValues);

  const handleChangeTab = (event: any, newValue: number) => {
    setSelectedTab(newValue);
    if (newValue === 0) setTipo('THINK');
    else if (newValue === 1) setTipo('PLAN');
    else if (newValue === 2) setTipo('GO TO MARKET');
  };

  const handleChange = (event: any) => {
    setExpanded(expanded == event ? -1 : event);
  };

  const handleChangeReload = (event: any) => {
    getComandas();
  };

  const handleChangeTipo = (event: any) => {
    setTipo(event);
  };

  const getComandas = () => {
    comandasActions
      .list()
      .then((resp: any) => {
        setEtapas(resp.etapas);
        setReunioes(resp.comandaEtapaReunioes);
      })
      .catch((e: any) => {
        toast.error(e);
      });
  };

  const obterProgressoPorTipo = () => {
    const etapasThink = etapas.filter((e) => e.idMetodologiaNavigation?.nome == 'THINK');
    const porcentagemThink =
      (reunioes.filter((r) => etapasThink.find((e) => e.id == r.tbEtapaId)).length /
        etapasThink.length) *
      100;
    const etapasPlan = etapas.filter((e) => e.idMetodologiaNavigation?.nome == 'PLAN');
    const porcentagemPlan =
      (reunioes.filter((r) => etapasPlan.find((e) => e.id == r.tbEtapaId)).length /
        etapasPlan.length) *
      100;
    const etapasGoToMarket = etapas.filter(
      (e) => e.idMetodologiaNavigation?.nome == 'GO TO MARKET',
    );
    const porcentagemGoToMarket =
      (reunioes.filter((r) => etapasGoToMarket.find((e) => e.id == r.tbEtapaId)).length /
        etapasGoToMarket.length) *
      100;

    const progressValues = [
      { value: porcentagemThink, color: '#003f88' },
      { value: porcentagemPlan, color: '#007bff' },
      { value: porcentagemGoToMarket, color: '#00b4d8' },
    ];
    setProgressoPorTipo(progressValues);
  };

  useEffect(() => {
    let r = reunioes.filter((reuniao) => reuniao.status?.toLowerCase() != 'agendada').length;

    if (r == 0) {
      setPorcentagem(0);
      return;
    }

    setPorcentagem((r / reunioes.length) * 100);
    setNumeroEtapasCompletas(r);
    obterProgressoPorTipo();
  }, [reunioes]);

  useEffect(() => {
    anexosActions.list().then((resp: any) => {
      setAnexos(resp);
    });

    getComandas();
  }, []);

  return (
    <GridBackground container>
      <Consultor />

      <Grid item xs={12}>
        <Breadcrumb />
      </Grid>

      <Grid item xs={12} sx={{ paddingBottom: '30px' }}>
        <Typography variant="h1">{auth?.comanda?.plano}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <PaperCard>
              <Typography variant="subtitle2">Progresso atual</Typography>
              <Typography variant="body2">
                Previsão para conclusão: {new Date(auth?.comanda?.dataFim!).toLocaleDateString()}
              </Typography>
              <CircularProgressWithLabel value={porcentagem} />
            </PaperCard>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <PaperCard>
              <Typography variant="subtitle2">
                Etapas completas{' '}
                <span style={{ color: 'var(--gray-medium)', fontWeight: '400' }}>(por tipo)</span>
              </Typography>

              <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                <CircularProgressWithMultipleLabels
                  values={progressoPorTipo}
                  valorExibir={numeroEtapasCompletas}
                />
                <Stack direction="column" spacing={1} mt={2} sx={{ marginLeft: '-60px' }}>
                  <Box display="flex" alignItems="center">
                    <Box width={10} height={10} bgcolor="#003f88" borderRadius="50%" mr={1} />
                    <Typography variant="body2">Think</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box width={10} height={10} bgcolor="#007bff" borderRadius="50%" mr={1} />
                    <Typography variant="body2">Plan</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box width={10} height={10} bgcolor="#00b4d8" borderRadius="50%" mr={1} />
                    <Typography variant="body2">Go to Market</Typography>
                  </Box>
                </Stack>
              </div>
            </PaperCard>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <PaperCard>
              <Typography variant="subtitle2">Anexos</Typography>
              <div style={{ marginTop: '20px' }}>
                {anexos.slice(0, 3).map((cv: any) => (
                  <>
                    <Divider />
                    <div
                      style={{
                        display: 'flex',
                        padding: '16px 24px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant="body2">{cv.arquivo}</Typography>

                      <Button
                        sx={{
                          height: '40px',
                          minWidth: '40px !important',
                          width: '40px !important',
                          borderRadius: '8px',
                          backgroundColor: '#EBF5FF',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CloudDownloadIcon
                          onClick={() => {
                            window.open(cv.arquivoLink, '_blank');
                          }}
                          sx={{ color: '#0D6EB3' }}
                        />
                      </Button>
                    </div>
                  </>
                ))}
              </div>
            </PaperCard>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '56px' }}>
        <Typography variant="body2">Selecione o seu momento de carreira:</Typography>
      </Grid>

      <Grid item xs={12}>
        <DivTab>
          <StyledTabs
            value={selectedTab}
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            centered
          >
            <StyledTab label="Think" />
            <StyledTab label="Plan" />
            <StyledTab label="Go to Market" />
          </StyledTabs>
        </DivTab>
      </Grid>

      <Grid
        item
        direction="row"
        spacing={0}
        sx={{ display: 'flex', flexWrap: 'wrap', alignContent: 'flex-start' }}
        xs={12}
        md={12}
        lg={8}
      >
        <Grid
          container
          direction="row"
          xs={12}
          md={12}
          sx={{ display: 'flex', padding: '20px 0px' }}
        >
          <Grid container xs={12} md={12}>
            {etapas.length > 0 &&
              etapas
                .filter((c) => c.idMetodologiaNavigation?.nome == tipo)
                .map((etapa, index) => (
                  <Etapa
                    etapa={etapa}
                    reuniao={reunioes.find((r) => r.tbEtapaId === etapa.id)}
                    index={index}
                    expanded={expanded}
                    handleChange={handleChange}
                    handleChangeReload={handleChangeReload}
                  />
                ))}
          </Grid>
        </Grid>
      </Grid>
    </GridBackground>
  );
};

const ButtonHome = styled(Button)`
  background-color: #0091cf !important;
  color: white !important;
`;

const PaperCard = styled(Paper)`
  background-color: var(--white-background) !important;
  border-radius: 8px !important;
  width: 384px;
  height: 273px;
  padding: 17px 16px;
`;

const StyledTab = styled(Tab)`
  height: 36px !important;
  &.Mui-selected {
    background-color: #ffffff;
    border-radius: 21px;
  }
  &.MuiTabs-indicator {
    display: none !important;
  }
`;

const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    display: none;
  }
`;

const DivTab = styled.div`
  background-color: #ebf5ff;
  border-radius: 25px;
  margin-top: 16px;
  padding: 4px;
  width: 390px;
`;

export default Programa;
