import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { findBreadcrumb } from "../../components/Breadcrumb/findBreadcrumb";
import type { IMenuItens } from "../../components/Header";
import { Grid2, LinearProgress, Typography } from "@mui/material";
import { GridBackground, TypographyDesc } from "../Acoes";
import Consultor from "../Home/Components/Consultor";
import Breadcrumb from "../../components/Breadcrumb";
import type { IParams } from "../RedefinirSenha/RedefinicaoSenha";
import { useMenusActions } from "../../states/menus/menu.actions";
import { createHTMLFromString } from "../PaginasDinamicas";
import type { IArea } from "../../commons/types/base";
import EventosInternos from "./Internos";
import EventosExternos from "./Externos";

const Eventos: React.FC = () => {
  const { id } = useParams<IParams>();
  const actions = useMenusActions();
  const location = useLocation();
  const currentPath = location.pathname;
  const [loading, setLoading] = useState<boolean>(false);
  const [interno, setInterno] = useState<boolean>(true);
  const [menu, setMenu] = useState<IArea>();

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      const menus = await actions.get();
      
      if (location.pathname.includes('secoes'))
        setMenu(menus.resultado.secoes.find((f: any) => f.id === Number(id)));
      else
        setMenu(menus.resultado.areas.find((f: any) => f.id === Number(id)));

      let structure = JSON.parse(localStorage.getItem('menusDinamicos') ?? '[]') as IMenuItens[];
      const breadcrumb = findBreadcrumb(currentPath, structure);
      if (breadcrumb!.findIndex((f: any) => f.nome.toLowerCase() == 'eventos externos') >= 0) {
        setInterno(false);
      } else {
        setInterno(true);
      }
      setLoading(false);
    }
    loadData();
  }, [id]);

  return (
    <GridBackground container direction="column" spacing={0} sx={{ minHeight: '90vh' }}>
      <Consultor />

      <Grid2 size={{ xs: 12 }}>
        <Breadcrumb />
      </Grid2>

      <Grid2 size={{ xs: 12 }} sx={{ paddingBottom: '30px' }}>
        <Typography variant="h1">{menu?.nome}</Typography>
      </Grid2>

      <Grid2 size={{ xs: 12 }} sx={{ paddingBottom: '10px' }}>
        <TypographyDesc variant="body1">
          {createHTMLFromString(menu?.descricao)}
        </TypographyDesc>
      </Grid2>

      <Grid2 size={{ xs: 12 }} sx={{ paddingTop: '48px' }}>
        {loading ? <LinearProgress /> :
          interno && menu
            ? (<EventosInternos tipo={menu?.nome!} />)
            : (<EventosExternos tipo={menu?.nome!} />)
        }
      </Grid2>
    </GridBackground>
  )
}

export default Eventos;