import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import ImgBackground from '../../../assets/intoo_E.png';
import { useDashboardActions } from '../../../states/dashboard/dashboard.actions';
import { authAtom } from '../../../states/usuarios/AuthState';
import Consultor from './Consultor';
import DashboardCard from './DashboardCard';

export interface IDashboard {
  etapasConcluidas: number;
  empresasSelecionadas: number;
  oportunidades: number;
  eventos: number;
  headhuntersSelecionados: number;
}

const Dashboard: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const actions = useDashboardActions();
  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState<IDashboard>();

  useEffect(() => {
    actions
      .get()
      .then((data) => setDashboard(data))
      .catch((e) => toast.error('Erro ao buscar dashboard'))
      .finally(() => setLoading(false));
  }, []);

  return (
    <DivDashboard>
      <Typography variant="h1">Bem-vindo, {auth?.nome ? auth!.nome.split(' ')[0] : ''}!</Typography>
      <Typography variant="body1" sx={{ marginTop: '16px' }}>
        Veja suas estatísticas, avance etapas e impulsione sua carreira.
      </Typography>
      <Consultor />

      <Box
        sx={{
          flexGrow: 1,
          marginTop: '72px',
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px',
          justifyContent: 'center',
        }}
      >
        <DashboardCard title="Etapas concluídas" value={dashboard?.etapasConcluidas} percentageChange={0} />
        <DashboardCard title="Oportunidades" value={dashboard?.oportunidades} percentageChange={0} />
        <DashboardCard title="Eventos" value={dashboard?.eventos} percentageChange={0} />
      </Box>
    </DivDashboard>
  );
};

const DivDashboard = styled.div`
  background-image: url(${ImgBackground});
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
  width: 100%;
  min-height: 80vh;
  margin-top: 100px;
  padding: 64px 32px 48px;
`;

export default Dashboard;
