import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { IComandaEtapaReunioes, IEtapa } from '../../../commons/types/crm';
import { authAtom } from '../../../states/usuarios/AuthState';
import NoticiaCard from './NoticiaCard';
import BannerCard from './BannerCard';
import { history } from '../../../helpers/history';
import { IConteudo, ISecao } from '../../../commons/types/base';
import { useMenusActions } from '../../../states/menus/menu.actions';

const Noticias: React.FC = () => {
  const bannerActions = useMenusActions();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [banners, setBanners] = useState<IConteudo[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex === banners.length - 1 ? 0 : prevIndex + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, [banners]);

  useEffect(() => {
    bannerActions.get().then((res: any) => {
      let conteudo = res.resultado.conteudos.filter((c: IConteudo) => c.idSecaos?.find((s: ISecao) => s.nome === "Tickers"));
      let banners = conteudo.filter((c: IConteudo) => c.anexo !== null);
      setBanners(banners);
    });
  }, []);

  const onClickBanner = (id: any) => {
    let conteudo = banners.find((f) => f.id === id);
    let area = conteudo?.idAreas?.find((a) => a.nome === 'Notícias');
    let secao = conteudo?.idSecaos?.find((a) => a.nome === 'Tickers');
    history.push(`/menus/${area?.id}/secoes/${secao?.id}/conteudos/${id}`);
  };

  const cardBanner = () => (
    <div style={{
      height: '100%', width: '100%',
      backgroundImage: `url(${banners[currentImageIndex]?.anexo})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius: "16px",
    }}>

      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 12, sm: 12 }} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }} onClick={() => onClickBanner(banners[currentImageIndex]?.id)}>
          <Grid size={{ xs: 12, md: 7, sm: 7 }} sx={{ padding: '20px', background: 'white', borderRadius: '16px' }}>
            <Typography variant="subtitle2" sx={{ color: 'black' }}>
              {banners[currentImageIndex]?.titulo}
            </Typography>
            <Typography variant="caption" sx={{ color: 'black', wordBreak: "break-all" }}>
              {banners[currentImageIndex]?.resumo?.substring(0, 100)}
            </Typography>
          </Grid>
        </Grid>

        <Grid onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex === banners.length - 1 ? 0 : prevIndex + 1))} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'white',
          width: '40px',
          height: '40px',
          borderRadius: '50px',
          cursor: 'pointer',
          position: 'absolute',
          right: '10px',
          top: '45%',
          boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
        }}>
          {'>'}
        </Grid>

        <Grid onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? banners.length - 1 : prevIndex - 1))} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'white',
          width: '40px',
          height: '40px',
          borderRadius: '50px',
          cursor: 'pointer',
          position: 'absolute',
          left: '10px',
          top: '45%',
          boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
        }}>
          {'<'}
        </Grid>

        <Grid sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'white',
          width: 'auto',
          height: '30px',
          borderRadius: '50px',
          cursor: 'pointer',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '5%',
          boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
        }}>
          {
            banners.map((b, index) => (
              (currentImageIndex === index ?
                <span style={{
                  padding: '5px',
                  borderRadius: '50px',
                  background: 'black',
                  margin: '5px',
                  cursor: 'pointer',
                }}
                  onClick={() => setCurrentImageIndex(index)}
                >
                </span>
                :
                <span style={{
                  padding: '5px',
                  borderRadius: '50px',
                  background: 'black',
                  margin: '5px',
                  cursor: 'pointer',
                  opacity: 0.5
                }}
                  onClick={() => setCurrentImageIndex(index)}
                >
                </span>
              )
            ))
          }
        </Grid>
      </Grid>
    </div >

  );

  return (
    <SuasNoticiasDiv>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
        </Grid>
        <Grid size={{ xs: 12, md: 3, sm: 3 }}>
          <NoticiaCard headerTitle="Últimas Notícias">
            <Grid size={{ xs: 12 }}>
            </Grid>
          </NoticiaCard>
        </Grid>
        <Grid size={{ xs: 12, md: 6, sm: 6 }}>
          <BannerCard headerTitle="">{cardBanner()}</BannerCard>
        </Grid>
        <Grid size={{ xs: 12, md: 3, sm: 3 }}>
          <NoticiaCard headerTitle="Agenda">{ }</NoticiaCard>
        </Grid>
      </Grid>
    </SuasNoticiasDiv>
  );
};

const SuasNoticiasDiv = styled.div`
  background: #f3f3f3;
  height: 620px;
  padding: 48px 32px;
`;

export default Noticias;
