/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    TableCell,
    Box,
    Checkbox,
    FormControlLabel,
    Drawer,
    Icon,
    Autocomplete,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Accordion,
    InputLabel,
    MenuItem,
    createFilterOptions,
    LinearProgress,
} from '@mui/material';
import { useEmpresasActions } from '../../../states/empresas/empresa.actions';
import { IContatoHistoricoEmpresa, INivelPosicao } from '../../../commons/types/crm';
import {
    ButtonStato,
    IconDrawer,
    SelectStato,
    TextFieldStato,
} from '../../../commons/styleds/styleds';
import { GridItemCadastro } from '../../../commons/styleds/grids';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/usuarios/AuthState';
import moment from 'moment';
import dayjs from 'dayjs';
import { AccordionDetails, AccordionSummary } from '../accordionAndTabPanel';
import {
    IAreaPosicao,
    IContatoHistoricoEmpresaPosicao,
    IEmpresaType,
    IEscopoResonsabilidade,
    IHistorico,
} from '../../../commons/styleds/historicoProdissional';
import { useConfigNivelFluenciasActions } from '../../../states/configNivelFluencia/configNivelFluencia.actions';
import { useContatoHistoricoEmpresaActions } from '../../../states/contatoHistoricoEmpresas/ContatoHistoricoEmpresaActions';
import { useAreaPosicaoActions } from '../../../states/areaPosicoes/areaPosicao.actions';
import { useContatoHistoricoEmpresaPosicoesActions } from '../../../states/ContatoHistoricoEmpresaPosicao/ContatoHistoricoEmpresaPosicoesActions';
import { useEscopoResponsabilidadeActions } from '../../../states/escopoResponsabilidade/escopoResponsabilidade.actions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNivelPosicaoActions } from '../../../states/nivelPosicao/nivelPosicao.actions';

interface Props {
    novoUsuario: boolean;
}

export const filter = createFilterOptions<IEmpresaType>();

const emptyPosicao: any = {
    id: 0,
    historicoEmpresaId: 0,
    cargo: '',
    realizacoes: '',
    responsabilidades: '',
    motivoSaida: '',
    superior: '',
    cargoAtual: false,
    numeroDeSubordinados: 0,
    areaId: 0,
    nivelId: 0,
    escopoId: 0,
    dataInicio: null,
    dataTermino: null,
};

const HistoricoProfissionalComponent: React.FC = () => {
    const auth = useRecoilValue(authAtom);
    const [open, setOpen] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingGet, setLoadingGet] = useState<boolean>(true);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const contatoHistoricoEmpresaActions = useContatoHistoricoEmpresaActions();
    const contatoHistoricoEmpresaPosicoesActions = useContatoHistoricoEmpresaPosicoesActions();
    const empresaActions = useEmpresasActions();
    const areaActions = useAreaPosicaoActions();
    const nivelActions = useNivelPosicaoActions();
    const escopoActions = useEscopoResponsabilidadeActions();

    const [contatoHistoricoEmpresas, setContatoHistoricoEmpresas] = useState<
        IContatoHistoricoEmpresa[]
    >([]);
    const [contatoHistoricoEmpresa, setContatoHistoricoEmpresa] =
        useState<IContatoHistoricoEmpresa>();

    const [tableData, setTableData] = useState<IHistorico[]>([]);
    const [value, setValue] = useState<IEmpresaType | null>(null);
    const [openEmpresaDialog, setOpenEmpresaDialog] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        razaosocial: '',
        cnpj: '',
    });

    const [empresas, setEmpresas] = useState<IEmpresaType[]>([]);
    const [areasPosicao, setAreasPosicao] = useState<IAreaPosicao[]>([]);
    const [niveisPosicao, setNiveisPosicao] = useState<INivelPosicao[]>([]);
    const [escoposPosicao, setEscoposPosicao] = useState<IEscopoResonsabilidade[]>([]);

    const { control, handleSubmit, register, watch, reset } = useForm<IContatoHistoricoEmpresa>({
        defaultValues: {
            posicoes: [emptyPosicao],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'posicoes',
    });

    const posicoesValue = watch('posicoes');

    const resetValues = () => {
        setContatoHistoricoEmpresa(undefined);
        setValue(null);
        reset({ posicoes: [emptyPosicao] });
    };

    const onSubmit = async (data: IContatoHistoricoEmpresa) => {
        if (!value) {
            alert('É necessário selecionar uma empresa');
            return;
        }

        if(data.posicoes.filter(f=> f.dataInicio == null).length > 0){
            toast.warning('Preencha a data inicial das posicoes');
            return;
        }

        setLoadingSave(true);

        if (!contatoHistoricoEmpresa) await adicionarNovoHistorico(data);
        else await editarHistorico(data);

        setLoadingSave(false);
        openDrawerHistorico(false);
        getContatoHistoricoEmpresas();
    };

    const adicionarNovoHistorico = async (data: IContatoHistoricoEmpresa) => {
        const historicoEmpresa = {
            contatoId: auth?.id,
            empresaId: value?.id,
        };

        const resp = await contatoHistoricoEmpresaActions.create(historicoEmpresa);
        await data.posicoes?.forEach(async (posicao: IContatoHistoricoEmpresaPosicao) => {
            const req = {
                ...posicao,
                historicoEmpresaId: resp.id,
                dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
                dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
            };
            const posicaoResp = await contatoHistoricoEmpresaPosicoesActions.create(req);
            resp.posicoes.push(posicaoResp);
        });
        toast.success('Histórico cadastrado');
    };

    const editarHistorico = async (data: IContatoHistoricoEmpresa) => {
        const posicoesNovas: IContatoHistoricoEmpresaPosicao[] = [];
        const posicoesAlteradas: IContatoHistoricoEmpresaPosicao[] = [];
        const posicoesRemovidas: IContatoHistoricoEmpresaPosicao[] = [];

        data.posicoes?.forEach((posicao) => {
            const req = {
                ...posicao,
                historicoEmpresaId: contatoHistoricoEmpresa!.id,
                dataInicio: posicao.dataInicio ? posicao.dataInicio.toISOString() : null,
                dataTermino: posicao.dataTermino ? posicao.dataTermino.toISOString() : null,
            } as any;
            if (posicao.id === 0) posicoesNovas.push(req);
            else if (contatoHistoricoEmpresa?.posicoes?.map((p) => p.id)?.includes(posicao?.id))
                posicoesAlteradas.push(req);
            else posicoesRemovidas.push(posicao);
        });
        const newRequests = posicoesNovas.map((posicao) =>
            contatoHistoricoEmpresaPosicoesActions.create(posicao),
        );

        const updateRequests = posicoesAlteradas.map((posicao) =>
            contatoHistoricoEmpresaPosicoesActions.update(posicao.id!, posicao),
        );
        const deleteRequests = posicoesRemovidas.map((posicao) =>
            contatoHistoricoEmpresaPosicoesActions.delete(posicao.id!),
        );

        await Promise.all([...newRequests, ...updateRequests, ...deleteRequests]);

        toast.success('Historico alterado');
    };

    const removerPosicao = (index: number) => {
        remove(index);
    };

    useEffect(() => {
        setLoadingGet(true);
        getContatoHistoricoEmpresas();
        getEmpresas();
        getAreas();
        getNiveis();
        getEscopos();

        setLoading(false);
    }, []);

    const getContatoHistoricoEmpresas = () => {
        contatoHistoricoEmpresaActions
            .list(false, `?contatoId=${auth?.id}`)
            .then((data: IContatoHistoricoEmpresa[]) => {
                setContatoHistoricoEmpresas(data);

                const dataTable = data.map((c: IContatoHistoricoEmpresa) => {
                    const posicoesSorted =
                        c.posicoes!.length > 0
                            ? c.posicoes?.sort(
                                (a, b) =>
                                    new Date(a.dataInicio as any).getTime() -
                                    new Date(b.dataInicio as any).getTime(),
                            )
                            : [];
                    return {
                        id: c.id,
                        empresa: c.empresaIdNavigation?.razaosocial,
                        dataInicio: posicoesSorted?.length ? formatDate(posicoesSorted[0]?.dataInicio) : null,
                        dataFim: posicoesSorted?.length
                            ? formatDate(posicoesSorted[posicoesSorted.length - 1]?.dataTermino)
                            : null,
                        atual: posicoesSorted?.length
                            ? posicoesSorted[posicoesSorted.length - 1].cargoAtual.toString()
                            : 'false',
                    } as IHistorico;
                });

                setTableData(dataTable);
                setLoadingGet(false);
            })
            .catch((error: any) => {
                setLoadingGet(false);
                toast.error(error);
            });
    };

    const formatDate = (date: Date | any) => {
        return moment(date).format('DD/MM/YYYY');
    };

    const getEmpresas = () => {
        empresaActions
            .list()
            .then((resp: any) => {
                setEmpresas([
                    {
                        "id": 1,
                        "razaosocial": "STATO Teste",
                        "cnpj": "65.073.537/0001-27",
                        "nomefantasia": undefined,
                        "matriz": true,
                        "ativo": true
                    },
                    {
                        "id": 2,
                        "razaosocial": "Bradesco",
                        "cnpj": "",
                        "nomefantasia": "BRADESCO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 3,
                        "razaosocial": "Braskem",
                        "cnpj": "",
                        "nomefantasia": "Braskem (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 55,
                        "razaosocial": "Fedex",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 56,
                        "razaosocial": "Sumitomo Industrias Pesadas do Brasil",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 57,
                        "razaosocial": "Roadcard",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 58,
                        "razaosocial": "Trench, Rossi & Watanabe Advogados",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 59,
                        "razaosocial": "Inditex",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 60,
                        "razaosocial": "CTC - Centro de Tecnologia Canavieira",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 61,
                        "razaosocial": "Voke - Notebooks e Desktops",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 64,
                        "razaosocial": "DOTZ (Portal de Relatórios)",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 65,
                        "razaosocial": "Machado Meyer",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 66,
                        "razaosocial": "Fujitsu",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 67,
                        "razaosocial": "Grupo Moura",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 68,
                        "razaosocial": "Cia. Hering",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 69,
                        "razaosocial": "Instituto Beja (Portal de Relatórios)",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 70,
                        "razaosocial": "Via Varejo GPA",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 71,
                        "razaosocial": "Ingram Micro",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 73,
                        "razaosocial": "Marsh",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 74,
                        "razaosocial": "HempMeds Brasil",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 75,
                        "razaosocial": "Cooxupé",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 76,
                        "razaosocial": "Atvos",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 77,
                        "razaosocial": "Eurochem",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 80,
                        "razaosocial": "Logixboard",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 81,
                        "razaosocial": "WexInc",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 83,
                        "razaosocial": "Conexa Saúde",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 84,
                        "razaosocial": "DPSP",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 85,
                        "razaosocial": "JLL",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 86,
                        "razaosocial": "Chama",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 87,
                        "razaosocial": "Construtora Sudoeste",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 88,
                        "razaosocial": "Freixenet SA",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 89,
                        "razaosocial": "GOODSTORAGE",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 90,
                        "razaosocial": "Portobello",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 91,
                        "razaosocial": "Studio Z | Ouze",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 92,
                        "razaosocial": "Mattos Filho",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 93,
                        "razaosocial": "Unicred",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 94,
                        "razaosocial": "Banco Fator",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 96,
                        "razaosocial": "Shinagawa",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 97,
                        "razaosocial": "Scuola Italiana",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 98,
                        "razaosocial": "LPS Company",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 99,
                        "razaosocial": "Concremat",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 100,
                        "razaosocial": "Techbiz",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 101,
                        "razaosocial": "Sprinklr Inc",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 102,
                        "razaosocial": "Datamine",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 104,
                        "razaosocial": "IMI Hydronic Engineering",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 105,
                        "razaosocial": "SMZTO",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 106,
                        "razaosocial": "Whatnot LTD",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 107,
                        "razaosocial": "Verum Partners",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 108,
                        "razaosocial": "SD Inovaçoes Gráficas",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 109,
                        "razaosocial": "Estrella de Galícia",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 110,
                        "razaosocial": "Saphyr Shopping Centers",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 111,
                        "razaosocial": "Dechra",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 112,
                        "razaosocial": "KWS",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 113,
                        "razaosocial": "Envu",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 114,
                        "razaosocial": "FertBela",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 115,
                        "razaosocial": "GSC",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 117,
                        "razaosocial": "Hexagon Mining Inc",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 118,
                        "razaosocial": "Ocrim",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 119,
                        "razaosocial": "Rede Globo",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 120,
                        "razaosocial": "CANTU IMPORTADORA",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 121,
                        "razaosocial": "International Paper",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 122,
                        "razaosocial": "MARELLI SISTEMAS AUTOMOTIVOS INDUSTRIA E COMERCIO BRASIL LTDA",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 123,
                        "razaosocial": "Grupo Orguel",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 124,
                        "razaosocial": "Nestle Nespresso",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 125,
                        "razaosocial": "ScanSource",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 126,
                        "razaosocial": "West Rock",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 127,
                        "razaosocial": "Ironhack",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 129,
                        "razaosocial": "Remessa Online",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 131,
                        "razaosocial": "Bloomin Brands",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 132,
                        "razaosocial": "FCA Group",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 133,
                        "razaosocial": "SPT Specialty Product Technologies",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 134,
                        "razaosocial": "SUCAFINA",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 135,
                        "razaosocial": "Prime Consultoria Imobiliária",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 136,
                        "razaosocial": "Sandvik",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 137,
                        "razaosocial": "Calvin Klein",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 138,
                        "razaosocial": "Galeazzi & Associados",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 139,
                        "razaosocial": "Interfood Sunny International food Comércio",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 141,
                        "razaosocial": "GranBio",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 142,
                        "razaosocial": "Xerox",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 144,
                        "razaosocial": "Austa Hospital",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 147,
                        "razaosocial": "Geosol",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 148,
                        "razaosocial": "Nova Transportadora do Sudeste",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 149,
                        "razaosocial": "Fundação Dom Cabral",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 150,
                        "razaosocial": "Studio Z",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 151,
                        "razaosocial": "Honda Automóveis do Brasil",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 152,
                        "razaosocial": "Nestlé",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 153,
                        "razaosocial": "International Paper",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 154,
                        "razaosocial": "Raizen",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 155,
                        "razaosocial": "Yamaha",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 156,
                        "razaosocial": "Grupo Cicopal",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 157,
                        "razaosocial": "Ecogen",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 158,
                        "razaosocial": "Sulgás - Cia de Gás",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 159,
                        "razaosocial": "Santa Helena",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 160,
                        "razaosocial": "Cedro Mineração",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 161,
                        "razaosocial": "Ecomenergia",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 162,
                        "razaosocial": "MERCER HUMAN",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 163,
                        "razaosocial": "GI GROUP",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 164,
                        "razaosocial": "Cervejaria Petropolis",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 165,
                        "razaosocial": "e-Core - Soluções em Tecnologia da Informação",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 166,
                        "razaosocial": "Taho",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 167,
                        "razaosocial": "Grupo Card",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 168,
                        "razaosocial": "Lanxess - Indústria de Produtos Quimicos e Plasticos ltda",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 169,
                        "razaosocial": "FCamara Consultoria",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 170,
                        "razaosocial": "IHS Towers",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 171,
                        "razaosocial": "Carl Zeiss",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 172,
                        "razaosocial": "Sylvamo",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 175,
                        "razaosocial": "Gerdau",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 176,
                        "razaosocial": "FIEP",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 178,
                        "razaosocial": "Liotecnica",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 179,
                        "razaosocial": "Leão Alimentos e Bebidas",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 181,
                        "razaosocial": "COBASI",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 182,
                        "razaosocial": "Etex Goup",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 183,
                        "razaosocial": "MAKRO ATACADISTA S.A",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 184,
                        "razaosocial": "Instituto Vita",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 187,
                        "razaosocial": "RNI Construtora",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 188,
                        "razaosocial": "Gol",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 189,
                        "razaosocial": "Dasa",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 190,
                        "razaosocial": "OWENS ILLINOIS",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 191,
                        "razaosocial": "Lenovo",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 192,
                        "razaosocial": "Telecom Italia Mobile",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 193,
                        "razaosocial": "EATON",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 194,
                        "razaosocial": "Grupo Ultra",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 195,
                        "razaosocial": "MRS LOGISTICA S.A",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 196,
                        "razaosocial": "Mapfre",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 197,
                        "razaosocial": "Sesi Senai",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 198,
                        "razaosocial": "Grupo São Martinho",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 199,
                        "razaosocial": "Comba Telecom",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 200,
                        "razaosocial": "Hm Engenharia",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 201,
                        "razaosocial": "DOCK (Ex Conductor Tecnologia S A)",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 202,
                        "razaosocial": "Universidade cruzeiro do sul",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 203,
                        "razaosocial": "Klabin",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 204,
                        "razaosocial": "Grupo Soma",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 205,
                        "razaosocial": "Nubank",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 206,
                        "razaosocial": "BAMIN",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 207,
                        "razaosocial": "Brivia ",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 208,
                        "razaosocial": "National Basketball Association",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 209,
                        "razaosocial": "Worley",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 210,
                        "razaosocial": "Teste Erick",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 211,
                        "razaosocial": "Raízen",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 212,
                        "razaosocial": "MARSH",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 213,
                        "razaosocial": "Tereos.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 214,
                        "razaosocial": "Nubank.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 216,
                        "razaosocial": "NEOGRID.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 218,
                        "razaosocial": "Flextronics",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 221,
                        "razaosocial": "Esfera Energia",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 222,
                        "razaosocial": "Rede Globo.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 223,
                        "razaosocial": "Unimed Seguradora",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 225,
                        "razaosocial": "Raízen",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 226,
                        "razaosocial": "MAKRO ATACADISTA S.A.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 227,
                        "razaosocial": "Harsco.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 228,
                        "razaosocial": "Harsco.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 229,
                        "razaosocial": "Carrefour ",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 230,
                        "razaosocial": "STATO",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 231,
                        "razaosocial": "Copagaz.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 232,
                        "razaosocial": "NK Store",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 233,
                        "razaosocial": "Lojas Riachuelo",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 234,
                        "razaosocial": "VOTORANTIM.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 235,
                        "razaosocial": "MACHADO MEYER.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 236,
                        "razaosocial": "SIMPRESS.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 237,
                        "razaosocial": "Tereos.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 238,
                        "razaosocial": "Hoerbiger Turbotech",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 239,
                        "razaosocial": "Grupo EP",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 240,
                        "razaosocial": "Copagaz.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 241,
                        "razaosocial": "CBRE GCS do Brasil Ltda.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 244,
                        "razaosocial": "Grupo EP",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 245,
                        "razaosocial": "Statoil",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 246,
                        "razaosocial": "Grupo EP",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 247,
                        "razaosocial": "Everyday Speech",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 248,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 249,
                        "razaosocial": "LIBBS.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 250,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 251,
                        "razaosocial": "Ignis Planning",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 252,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 254,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 255,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 256,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 257,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 258,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 259,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 260,
                        "razaosocial": "Dasa.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 261,
                        "razaosocial": "Usiminas.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 262,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 263,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 264,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 265,
                        "razaosocial": "Ecogen.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 266,
                        "razaosocial": "Ecogen.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 267,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 268,
                        "razaosocial": "Copagaz.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 269,
                        "razaosocial": "IT Mídia",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 270,
                        "razaosocial": "Hortifruti",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 272,
                        "razaosocial": "TGROUP SERVIÇOS ONLINE",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 273,
                        "razaosocial": "MACHADO MEYER.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 275,
                        "razaosocial": "Spirax Sarco Inc",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 276,
                        "razaosocial": "Flextronics",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 277,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 278,
                        "razaosocial": "MACHADO MEYER.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 279,
                        "razaosocial": "Fundação Roberto Marinho - Canal Futura",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 280,
                        "razaosocial": "McKinsey & Company.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 281,
                        "razaosocial": "Grupo Bunzl",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 282,
                        "razaosocial": "ACCENTURE",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 285,
                        "razaosocial": "ROADCARD",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 286,
                        "razaosocial": "Everis",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 287,
                        "razaosocial": "Cirion Technologies",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 288,
                        "razaosocial": "FMU.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 289,
                        "razaosocial": "Tereos.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 291,
                        "razaosocial": "Sixbell Brasil",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 292,
                        "razaosocial": "LAVORO AGRO",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 293,
                        "razaosocial": "Gol.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 294,
                        "razaosocial": "Code Climate INC",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 296,
                        "razaosocial": "Kemira Chemicals Brasil Ltda.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 297,
                        "razaosocial": "EDP.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 298,
                        "razaosocial": "Paytech Holding",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 299,
                        "razaosocial": "Concremat.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 301,
                        "razaosocial": "Grupo Card.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 302,
                        "razaosocial": "Senac",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 303,
                        "razaosocial": "CITROSUCO",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 304,
                        "razaosocial": "Magneti Marelli.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 305,
                        "razaosocial": "Essity do Brasil",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 306,
                        "razaosocial": "Tempo Assist.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 307,
                        "razaosocial": "Victaulic",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 308,
                        "razaosocial": "MATTOS FILHO",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 309,
                        "razaosocial": "Mitre Realty Construção Civil, Incorporação e Vendas Imobiliárias",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 310,
                        "razaosocial": "Google Teste",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 311,
                        "razaosocial": "Smr Automotive",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 312,
                        "razaosocial": "Epic Games",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 314,
                        "razaosocial": "HONDA TRADING.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 316,
                        "razaosocial": "Techbiz.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 317,
                        "razaosocial": "FEDEX",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 318,
                        "razaosocial": "MSC Cruzeiros",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 320,
                        "razaosocial": "Greenpeace",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 321,
                        "razaosocial": "Shutterstock",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 323,
                        "razaosocial": "Hm Engenharia E Construcoes Sa",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 324,
                        "razaosocial": "Portobello.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 325,
                        "razaosocial": "Hexagon Mining Inc.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 326,
                        "razaosocial": "Imedato Nextway",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 327,
                        "razaosocial": "Elementis Global",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 328,
                        "razaosocial": "Santista.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 329,
                        "razaosocial": "ESPRO - Ensino Social Profissionalizante",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 330,
                        "razaosocial": "JLL.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 332,
                        "razaosocial": "UiPath",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 333,
                        "razaosocial": "HM ENG. E CONSTRUCOES LTDA",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 334,
                        "razaosocial": "DPSP.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 335,
                        "razaosocial": "Insper",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 336,
                        "razaosocial": "GRUPO PRINER",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 337,
                        "razaosocial": "Porshe",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 338,
                        "razaosocial": "Ingram Micro.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 339,
                        "razaosocial": "Drakar - Ledervin - Tork",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 340,
                        "razaosocial": "Tupinambá Mobilidade Elétrica",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 341,
                        "razaosocial": "LED10",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 342,
                        "razaosocial": "Grupo Moura.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 343,
                        "razaosocial": "Necta Gas",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 344,
                        "razaosocial": "Unicred.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 345,
                        "razaosocial": "Amway",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 346,
                        "razaosocial": "Paxos Technology Solutions",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 347,
                        "razaosocial": "Sauter Digital.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 349,
                        "razaosocial": "Companhia Brasileira de Metalurgia e Mineração",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 350,
                        "razaosocial": "COBASI.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 351,
                        "razaosocial": "DMI isolantes - Krempel",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 352,
                        "razaosocial": "GI GROUP.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 353,
                        "razaosocial": "Nov.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 354,
                        "razaosocial": "Epson do Brasil",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 355,
                        "razaosocial": "Célia Helena Centro de Artes e Educação.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 356,
                        "razaosocial": "",
                        "cnpj": "",
                        "nomefantasia": "Itaú ",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 357,
                        "razaosocial": "Itaú",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 358,
                        "razaosocial": "Itau Projeto Iti",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 359,
                        "razaosocial": "Oswaldo Cruz Química",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 360,
                        "razaosocial": "betesys@hotmail.com",
                        "cnpj": "",
                        "nomefantasia": "Valmont (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 361,
                        "razaosocial": "Valmont",
                        "cnpj": "",
                        "nomefantasia": "Valmont (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 362,
                        "razaosocial": "Groupe Seb | Arno",
                        "cnpj": "",
                        "nomefantasia": "Groupe Seb | Arno (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 363,
                        "razaosocial": "Hilti Inc",
                        "cnpj": "",
                        "nomefantasia": "Hilti Inc (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 364,
                        "razaosocial": "teste TI",
                        "cnpj": "01.023.658/000125",
                        "nomefantasia": "teste empresa TI",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 365,
                        "razaosocial": "Naos",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 366,
                        "razaosocial": "The Boston Consulting Group - BCG",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 367,
                        "razaosocial": "Saint-Gobain",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 368,
                        "razaosocial": "Fundação dom Cabral",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 369,
                        "razaosocial": "Plastirrico",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 370,
                        "razaosocial": "THALES GROUP.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 372,
                        "razaosocial": "Havana.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 373,
                        "razaosocial": "Aliança Energia",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 374,
                        "razaosocial": "OCRIM.",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 375,
                        "razaosocial": "DUMONT SAAB",
                        "cnpj": "",
                        "nomefantasia": "DUMONT SAAB (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 376,
                        "razaosocial": "Brasfilter",
                        "cnpj": "",
                        "nomefantasia": "Brasfilter (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 377,
                        "razaosocial": "AllCare",
                        "cnpj": "",
                        "nomefantasia": "AllCare (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 378,
                        "razaosocial": "Vizir",
                        "cnpj": "",
                        "nomefantasia": "Vizir (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 379,
                        "razaosocial": "PEPPERL + FUCHS INC",
                        "cnpj": "",
                        "nomefantasia": "PEPPERL + FUCHS INC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 380,
                        "razaosocial": "Wheaton Brasil",
                        "cnpj": "",
                        "nomefantasia": "Wheaton Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 381,
                        "razaosocial": "Quantum",
                        "cnpj": "",
                        "nomefantasia": "Quantum (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 382,
                        "razaosocial": "Grupo Marista UBEE",
                        "cnpj": "",
                        "nomefantasia": "Grupo Marista UBEE (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 383,
                        "razaosocial": "Folha de São Paulo",
                        "cnpj": "",
                        "nomefantasia": "Folha de São Paulo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 384,
                        "razaosocial": "Fundação Roberto Marinho",
                        "cnpj": "",
                        "nomefantasia": "Fundação Roberto Marinho (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 385,
                        "razaosocial": "Strong",
                        "cnpj": "",
                        "nomefantasia": "Strong (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 386,
                        "razaosocial": "Climatempo",
                        "cnpj": "",
                        "nomefantasia": "Climatempo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 387,
                        "razaosocial": "Monte Rodovias",
                        "cnpj": "",
                        "nomefantasia": "Monte Rodovias (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 388,
                        "razaosocial": "Grupo Agis",
                        "cnpj": "",
                        "nomefantasia": "Grupo Agis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 389,
                        "razaosocial": "Universidade Estácio de Sá",
                        "cnpj": "",
                        "nomefantasia": "Universidade Estácio de Sá (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 390,
                        "razaosocial": "Usiminas",
                        "cnpj": "",
                        "nomefantasia": "Usiminas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 391,
                        "razaosocial": "Somfy Brasil",
                        "cnpj": "",
                        "nomefantasia": "Somfy Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 392,
                        "razaosocial": "Tel&Com",
                        "cnpj": "",
                        "nomefantasia": "Tel&Com (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 393,
                        "razaosocial": "CPB - Comitê Paralímpico Brasileiro",
                        "cnpj": "",
                        "nomefantasia": "CPB - Comitê Paralímpico Brasileiro (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 394,
                        "razaosocial": "Smr Automotive",
                        "cnpj": "",
                        "nomefantasia": "Smr Automotive (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 395,
                        "razaosocial": "Alubar",
                        "cnpj": "",
                        "nomefantasia": "Alubar (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 396,
                        "razaosocial": "Oncoclínicas do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Oncoclínicas do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 397,
                        "razaosocial": "HM ENG. E CONSTRUCOES LTDA",
                        "cnpj": "",
                        "nomefantasia": "HM ENG. E CONSTRUCOES LTDA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 398,
                        "razaosocial": "Médico Sem Fila",
                        "cnpj": "",
                        "nomefantasia": "Médico Sem Fila (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 399,
                        "razaosocial": "Galvani Fertilizantes",
                        "cnpj": "",
                        "nomefantasia": "Galvani Fertilizantes (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 400,
                        "razaosocial": "Lanxess - Indústria de Produtos Quimicos e Plasticos",
                        "cnpj": "",
                        "nomefantasia": "Lanxess - Indústria de Produtos Quimicos e Plasticos (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 401,
                        "razaosocial": "UHG - United Health Group Brasil (Portal de Relatórios)",
                        "cnpj": "",
                        "nomefantasia": "UHG - United Health Group Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 402,
                        "razaosocial": "Enel",
                        "cnpj": "",
                        "nomefantasia": "Enel (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 403,
                        "razaosocial": "Keeggo",
                        "cnpj": "",
                        "nomefantasia": "Keeggo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 404,
                        "razaosocial": "Azimut Watch Company",
                        "cnpj": "",
                        "nomefantasia": "Azimut Watch Company (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 405,
                        "razaosocial": "Tempo Assist",
                        "cnpj": "",
                        "nomefantasia": "Tempo Assist (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 406,
                        "razaosocial": "GDC Alimentos S.A.",
                        "cnpj": "",
                        "nomefantasia": "GDC Alimentos S.A. (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 407,
                        "razaosocial": "HeartCo",
                        "cnpj": "",
                        "nomefantasia": "HeartCo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 408,
                        "razaosocial": "Bb mapfre",
                        "cnpj": "",
                        "nomefantasia": "Bb mapfre (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 409,
                        "razaosocial": "Ambar Tech",
                        "cnpj": "",
                        "nomefantasia": "Ambar Tech (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 410,
                        "razaosocial": "Ingram Micro",
                        "cnpj": "",
                        "nomefantasia": "Ingram Micro (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 411,
                        "razaosocial": "Prime Cater",
                        "cnpj": "",
                        "nomefantasia": "Prime Cater (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 412,
                        "razaosocial": "LPS Company",
                        "cnpj": "",
                        "nomefantasia": "LPS Company (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 413,
                        "razaosocial": "Rede Globo",
                        "cnpj": "",
                        "nomefantasia": "Rede Globo (Portal de Relatório)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 414,
                        "razaosocial": "Enxuto Supermercados",
                        "cnpj": "",
                        "nomefantasia": "Enxuto Supermercados (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 415,
                        "razaosocial": "Dupont",
                        "cnpj": "",
                        "nomefantasia": "Dupont (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 416,
                        "razaosocial": "Telefonica S.A.",
                        "cnpj": "",
                        "nomefantasia": "Telefonica S.A. (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 417,
                        "razaosocial": "Lean Institute Brasil",
                        "cnpj": "",
                        "nomefantasia": "Lean Institute Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 418,
                        "razaosocial": "Enxuto Supermercados",
                        "cnpj": "",
                        "nomefantasia": "Enxuto Supermercados (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 419,
                        "razaosocial": "Freixenet SA",
                        "cnpj": "",
                        "nomefantasia": "Freixenet SA (Portal de Relatórios) ",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 420,
                        "razaosocial": "Lubrizol",
                        "cnpj": "",
                        "nomefantasia": "Lubrizol (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 421,
                        "razaosocial": "JSL",
                        "cnpj": "",
                        "nomefantasia": "JSL (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 422,
                        "razaosocial": "MSC Mediterranean Shipping do Brasil",
                        "cnpj": "",
                        "nomefantasia": "MSC Mediterranean Shipping do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 423,
                        "razaosocial": "Coop - Cooperativa de Consumo",
                        "cnpj": "",
                        "nomefantasia": "Coop - Cooperativa de Consumo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 424,
                        "razaosocial": "Kemira Chemicals Brasil Ltda",
                        "cnpj": "",
                        "nomefantasia": "Kemira Chemicals Brasil Ltda (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 425,
                        "razaosocial": "Raízen-Biosev",
                        "cnpj": "",
                        "nomefantasia": "Raízen-Biosev (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 426,
                        "razaosocial": "Altera & Ocyan",
                        "cnpj": "",
                        "nomefantasia": "Altera & Ocyan (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 427,
                        "razaosocial": "Dasa",
                        "cnpj": "",
                        "nomefantasia": "Dasa (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 428,
                        "razaosocial": "Vaccinar Indústria e Comércio",
                        "cnpj": "",
                        "nomefantasia": "Vaccinar Indústria e Comércio (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 429,
                        "razaosocial": "VIABAHIA",
                        "cnpj": "",
                        "nomefantasia": "VIABAHIA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 430,
                        "razaosocial": "Gol",
                        "cnpj": "",
                        "nomefantasia": "Gol (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 431,
                        "razaosocial": "AB Mais Urbanismo",
                        "cnpj": "",
                        "nomefantasia": "AB Mais Urbanismo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 432,
                        "razaosocial": "L.E.K. Consulting",
                        "cnpj": "",
                        "nomefantasia": "L.E.K. Consulting (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 433,
                        "razaosocial": "Hortifruti",
                        "cnpj": "",
                        "nomefantasia": "Hortifruti (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 434,
                        "razaosocial": "Laticínios Scala",
                        "cnpj": "",
                        "nomefantasia": "Laticínios Scala (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 435,
                        "razaosocial": "Esfera Energia",
                        "cnpj": "",
                        "nomefantasia": "Esfera Energia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 436,
                        "razaosocial": "At&m",
                        "cnpj": "",
                        "nomefantasia": "At&m (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 437,
                        "razaosocial": "Rede Impar Serviços Hospitalares",
                        "cnpj": "",
                        "nomefantasia": "Rede Impar Serviços Hospitalares (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 438,
                        "razaosocial": "Pipefy",
                        "cnpj": "",
                        "nomefantasia": "Pipefy (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 439,
                        "razaosocial": "Nortis",
                        "cnpj": "",
                        "nomefantasia": "Nortis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 440,
                        "razaosocial": "Studio Z",
                        "cnpj": "",
                        "nomefantasia": "Studio Z  (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 441,
                        "razaosocial": "Ebanx",
                        "cnpj": "",
                        "nomefantasia": "Ebanx (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 442,
                        "razaosocial": "KWS",
                        "cnpj": "",
                        "nomefantasia": "KWS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 443,
                        "razaosocial": "Pastificio Selmi",
                        "cnpj": "",
                        "nomefantasia": "Pastificio Selmi (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 444,
                        "razaosocial": "Cetrel SA",
                        "cnpj": "",
                        "nomefantasia": "Cetrel SA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 445,
                        "razaosocial": "Argo Energia",
                        "cnpj": "",
                        "nomefantasia": "Argo Energia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 446,
                        "razaosocial": "Aerys enery",
                        "cnpj": "",
                        "nomefantasia": "Aerys enery (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 447,
                        "razaosocial": "Czarnikow Brasil - CZ",
                        "cnpj": "",
                        "nomefantasia": "Czarnikow Brasil - CZ (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 448,
                        "razaosocial": "Studio Z | Ouze",
                        "cnpj": "",
                        "nomefantasia": "Studio Z | Ouze (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 449,
                        "razaosocial": "Epiroc",
                        "cnpj": "",
                        "nomefantasia": "Epiroc (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 450,
                        "razaosocial": "PROSSEGUR",
                        "cnpj": "",
                        "nomefantasia": "PROSSEGUR (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 451,
                        "razaosocial": "Math Group",
                        "cnpj": "",
                        "nomefantasia": "Math Group (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 452,
                        "razaosocial": "LOXAM - Degraus Aluguel de Equipamentos",
                        "cnpj": "",
                        "nomefantasia": "LOXAM - Degraus Aluguel de Equipamentos (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 453,
                        "razaosocial": "Minerva S.A",
                        "cnpj": "",
                        "nomefantasia": "Minerva S.A (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 454,
                        "razaosocial": "Nextsoft",
                        "cnpj": "",
                        "nomefantasia": "Nextsoft (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 455,
                        "razaosocial": "SD Inovaçoes Gráficas",
                        "cnpj": "",
                        "nomefantasia": "SD Inovaçoes Gráficas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 456,
                        "razaosocial": "Ecomenergia",
                        "cnpj": "",
                        "nomefantasia": "Ecomenergia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 457,
                        "razaosocial": "BO Paper Brasil",
                        "cnpj": "",
                        "nomefantasia": "BO Paper Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 458,
                        "razaosocial": "DOCK",
                        "cnpj": "",
                        "nomefantasia": "DOCK (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 459,
                        "razaosocial": "Biochimico",
                        "cnpj": "",
                        "nomefantasia": "Biochimico (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 460,
                        "razaosocial": "Arko Fertilizantes",
                        "cnpj": "",
                        "nomefantasia": "Arko Fertilizantes (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 461,
                        "razaosocial": "ActiveCampaign LLC",
                        "cnpj": "",
                        "nomefantasia": "ActiveCampaign LLC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 462,
                        "razaosocial": "Integra Associados",
                        "cnpj": "",
                        "nomefantasia": "Integra Associados (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 463,
                        "razaosocial": "Genesys",
                        "cnpj": "",
                        "nomefantasia": "Genesys (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 464,
                        "razaosocial": "Bluefit Academias",
                        "cnpj": "",
                        "nomefantasia": "Bluefit Academias (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 465,
                        "razaosocial": "WexInc",
                        "cnpj": "",
                        "nomefantasia": "WexInc (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 466,
                        "razaosocial": "Mindbody Inc",
                        "cnpj": "",
                        "nomefantasia": "Mindbody Inc (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 467,
                        "razaosocial": "Hotmart",
                        "cnpj": "",
                        "nomefantasia": "Hotmart (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 468,
                        "razaosocial": "Comgás",
                        "cnpj": "",
                        "nomefantasia": "Comgás (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 469,
                        "razaosocial": "Fednav Brasil Agencia Maritima",
                        "cnpj": "",
                        "nomefantasia": "Fednav Brasil Agencia Maritima (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 470,
                        "razaosocial": "Superlógica Tecnologias",
                        "cnpj": "",
                        "nomefantasia": "Superlógica Tecnologias (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 471,
                        "razaosocial": "Swile Benefícios",
                        "cnpj": "",
                        "nomefantasia": "Swile Benefícios (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 472,
                        "razaosocial": "Florescer Limpeza",
                        "cnpj": "",
                        "nomefantasia": "Florescer Limpeza (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 473,
                        "razaosocial": "FF Solutions",
                        "cnpj": "",
                        "nomefantasia": "FF Solutions (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 474,
                        "razaosocial": "ICN Itaguaí Construções Navais",
                        "cnpj": "",
                        "nomefantasia": "ICN Itaguaí Construções Navais (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 475,
                        "razaosocial": "SEEDCORP HO",
                        "cnpj": "",
                        "nomefantasia": "SEEDCORP HO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 476,
                        "razaosocial": "Mirakl",
                        "cnpj": "",
                        "nomefantasia": "Mirakl (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 477,
                        "razaosocial": "Célia Helena Centro de Artes e Educação",
                        "cnpj": "",
                        "nomefantasia": "Célia Helena Centro de Artes e Educação (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 478,
                        "razaosocial": "B LAB GLOBAL",
                        "cnpj": "",
                        "nomefantasia": "B LAB GLOBAL (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 479,
                        "razaosocial": "Avalara",
                        "cnpj": "",
                        "nomefantasia": "Avalara (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 480,
                        "razaosocial": "EUREKA ",
                        "cnpj": "",
                        "nomefantasia": "EUREKA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 481,
                        "razaosocial": "Sulgás - Cia de Gás",
                        "cnpj": "",
                        "nomefantasia": "Sulgás - Cia de Gás (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 482,
                        "razaosocial": "Gás Brasiliano",
                        "cnpj": "",
                        "nomefantasia": "Gás Brasiliano (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 483,
                        "razaosocial": "Blue Fit Participações",
                        "cnpj": "",
                        "nomefantasia": "Blue Fit Participações (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 484,
                        "razaosocial": "Sigga",
                        "cnpj": "",
                        "nomefantasia": "Sigga (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 485,
                        "razaosocial": "Instituto Beja",
                        "cnpj": "",
                        "nomefantasia": "Instituto Beja (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 486,
                        "razaosocial": "Célia Helena Faculdades",
                        "cnpj": "",
                        "nomefantasia": "Célia Helena Faculdades (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 487,
                        "razaosocial": "Oncoclinicas",
                        "cnpj": "",
                        "nomefantasia": "Oncoclinicas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 488,
                        "razaosocial": "Hoerbiger Turbotech",
                        "cnpj": "",
                        "nomefantasia": "Hoerbiger Turbotech (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 489,
                        "razaosocial": "Grupo Cicopal",
                        "cnpj": "",
                        "nomefantasia": "Grupo Cicopal (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 490,
                        "razaosocial": "Grupo EP",
                        "cnpj": "",
                        "nomefantasia": "Grupo EP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 491,
                        "razaosocial": "Algar Telecom",
                        "cnpj": "",
                        "nomefantasia": "Algar Telecom (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 492,
                        "razaosocial": "Galeazzi & Associados",
                        "cnpj": "",
                        "nomefantasia": "Galeazzi & Associados (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 493,
                        "razaosocial": "Grupo Soma",
                        "cnpj": "",
                        "nomefantasia": "Grupo Soma (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 494,
                        "razaosocial": "Etex Goup",
                        "cnpj": "",
                        "nomefantasia": "Etex Goup (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 495,
                        "razaosocial": "Vaxxinova Brasil",
                        "cnpj": "",
                        "nomefantasia": "Vaxxinova Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 496,
                        "razaosocial": "Marelli Mauá",
                        "cnpj": "",
                        "nomefantasia": "Marelli Mauá (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 497,
                        "razaosocial": "HOK TRANSPORTES LTDA",
                        "cnpj": "",
                        "nomefantasia": "HOK TRANSPORTES LTDA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 498,
                        "razaosocial": "Inditex",
                        "cnpj": "",
                        "nomefantasia": "Inditex (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 499,
                        "razaosocial": "Inventus Power",
                        "cnpj": "",
                        "nomefantasia": "Inventus Power (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 500,
                        "razaosocial": "Everyday Speech",
                        "cnpj": "",
                        "nomefantasia": "Everyday Speech (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 501,
                        "razaosocial": "COGNA",
                        "cnpj": "",
                        "nomefantasia": "COGNA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 502,
                        "razaosocial": "McKinsey & Company",
                        "cnpj": "",
                        "nomefantasia": "McKinsey & Company (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 503,
                        "razaosocial": "Marelli Santo Amaro",
                        "cnpj": "",
                        "nomefantasia": "Marelli Santo Amaro (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 504,
                        "razaosocial": "Ignis Planning",
                        "cnpj": "",
                        "nomefantasia": "Ignis Planning (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 505,
                        "razaosocial": "Ironhack",
                        "cnpj": "",
                        "nomefantasia": "Ironhack (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 506,
                        "razaosocial": "Braspine",
                        "cnpj": "",
                        "nomefantasia": "Braspine (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 507,
                        "razaosocial": "Santa Helena",
                        "cnpj": "",
                        "nomefantasia": "Santa Helena (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 508,
                        "razaosocial": "Grupo Eureka",
                        "cnpj": "",
                        "nomefantasia": "Grupo Eureka (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 509,
                        "razaosocial": "Instituto Vita",
                        "cnpj": "",
                        "nomefantasia": "Instituto Vita (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 510,
                        "razaosocial": "Verum Partners",
                        "cnpj": "",
                        "nomefantasia": "Verum Partners (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 511,
                        "razaosocial": "GTV",
                        "cnpj": "",
                        "nomefantasia": "GTV (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 512,
                        "razaosocial": "Grupo Delly's",
                        "cnpj": "",
                        "nomefantasia": "Grupo Delly's (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 513,
                        "razaosocial": "Cedro Mineração",
                        "cnpj": "",
                        "nomefantasia": "Cedro Mineração (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 514,
                        "razaosocial": "RNI Construtora",
                        "cnpj": "",
                        "nomefantasia": "RNI Construtora (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 515,
                        "razaosocial": "Eco Energias Renováveis",
                        "cnpj": "",
                        "nomefantasia": "Eco Energias Renováveis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 516,
                        "razaosocial": "TGROUP",
                        "cnpj": "",
                        "nomefantasia": "TGROUP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 517,
                        "razaosocial": "Grupo Orguel",
                        "cnpj": "",
                        "nomefantasia": "Grupo Orguel (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 518,
                        "razaosocial": "Ecogen",
                        "cnpj": "",
                        "nomefantasia": "Ecogen (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 519,
                        "razaosocial": "Bridgestone do Brasil Industria Comercio",
                        "cnpj": "",
                        "nomefantasia": "Bridgestone do Brasil Industria Comercio (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 520,
                        "razaosocial": "Lundin Mining",
                        "cnpj": "",
                        "nomefantasia": "Lundin Mining (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 521,
                        "razaosocial": "DUMONT SAAB",
                        "cnpj": "",
                        "nomefantasia": "DUMONT SAAB (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 522,
                        "razaosocial": "Brasfilter",
                        "cnpj": "",
                        "nomefantasia": "Brasfilter (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 523,
                        "razaosocial": "3R Petroleum",
                        "cnpj": "",
                        "nomefantasia": "3R Petroleum (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 524,
                        "razaosocial": "AAK",
                        "cnpj": "",
                        "nomefantasia": "AAK (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 525,
                        "razaosocial": "Açotubo",
                        "cnpj": "",
                        "nomefantasia": "Açotubo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 526,
                        "razaosocial": "Adar",
                        "cnpj": "",
                        "nomefantasia": "Adar (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 527,
                        "razaosocial": "Adm Archers Daniel Midland",
                        "cnpj": "",
                        "nomefantasia": "Adm Archers Daniel Midland (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 528,
                        "razaosocial": "AFIP",
                        "cnpj": "",
                        "nomefantasia": "AFIP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 529,
                        "razaosocial": "Aliança Metalurgica",
                        "cnpj": "",
                        "nomefantasia": "Aliança Metalurgica (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 530,
                        "razaosocial": "Alpargatas",
                        "cnpj": "",
                        "nomefantasia": "Alpargatas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 531,
                        "razaosocial": "Amcor",
                        "cnpj": "",
                        "nomefantasia": "Amcor (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 532,
                        "razaosocial": "American Axle & Manufacturing",
                        "cnpj": "",
                        "nomefantasia": "American Axle & Manufacturing (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 533,
                        "razaosocial": "ANSA",
                        "cnpj": "",
                        "nomefantasia": "ANSA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 534,
                        "razaosocial": "Arcadis Brasil",
                        "cnpj": "",
                        "nomefantasia": "Arcadis Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 535,
                        "razaosocial": "Aspect",
                        "cnpj": "",
                        "nomefantasia": "Aspect (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 536,
                        "razaosocial": "Atech - Negocios em Tecnologia S.A",
                        "cnpj": "",
                        "nomefantasia": "Atech - Negocios em Tecnologia S.A (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 537,
                        "razaosocial": "Atlas Copco",
                        "cnpj": "",
                        "nomefantasia": "Atlas Copco (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 538,
                        "razaosocial": "AUTO AVALIAR",
                        "cnpj": "",
                        "nomefantasia": "AUTO AVALIAR (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 539,
                        "razaosocial": "Automatech Sistemas de Automação",
                        "cnpj": "",
                        "nomefantasia": "Automatech Sistemas de Automação (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 540,
                        "razaosocial": "Bahia Mineração",
                        "cnpj": "",
                        "nomefantasia": "Bahia Mineração (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 541,
                        "razaosocial": "BANCO CCB",
                        "cnpj": "",
                        "nomefantasia": "BANCO CCB (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 542,
                        "razaosocial": "BANCO DAYCOVAL",
                        "cnpj": "",
                        "nomefantasia": "BANCO DAYCOVAL (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 543,
                        "razaosocial": "Banco Plural",
                        "cnpj": "",
                        "nomefantasia": "Banco Plural (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 544,
                        "razaosocial": "BBM LOGISTICA",
                        "cnpj": "",
                        "nomefantasia": "BBM LOGISTICA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 545,
                        "razaosocial": "beckman coulter",
                        "cnpj": "",
                        "nomefantasia": "beckman coulter (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 546,
                        "razaosocial": "Ben & Jerry",
                        "cnpj": "",
                        "nomefantasia": "Ben & Jerry (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 547,
                        "razaosocial": "BHIP",
                        "cnpj": "",
                        "nomefantasia": "BHIP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 548,
                        "razaosocial": "Bioenergia",
                        "cnpj": "",
                        "nomefantasia": "Bioenergia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 549,
                        "razaosocial": "Bionovis",
                        "cnpj": "",
                        "nomefantasia": "Bionovis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 550,
                        "razaosocial": "Bradesco",
                        "cnpj": "",
                        "nomefantasia": "Bradesco (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 551,
                        "razaosocial": "British Consulate",
                        "cnpj": "",
                        "nomefantasia": "British Consulate (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 552,
                        "razaosocial": "British Petroleum",
                        "cnpj": "",
                        "nomefantasia": "British Petroleum (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 553,
                        "razaosocial": "Brownfield Aker Solutions - CSEMIL",
                        "cnpj": "",
                        "nomefantasia": "Brownfield Aker Solutions - CSEMIL (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 554,
                        "razaosocial": "BUREAU VERITAS",
                        "cnpj": "",
                        "nomefantasia": "BUREAU VERITAS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 555,
                        "razaosocial": "Callflex",
                        "cnpj": "",
                        "nomefantasia": "Callflex (Portal de Realtórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 556,
                        "razaosocial": "CAPTAMED",
                        "cnpj": "",
                        "nomefantasia": "CAPTAMED (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 557,
                        "razaosocial": "CARGO X",
                        "cnpj": "",
                        "nomefantasia": "CARGO X (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 558,
                        "razaosocial": "CARREFOUR COMERCIO E INDUSTRIA LTDA",
                        "cnpj": "",
                        "nomefantasia": "CARREFOUR COMERCIO E INDUSTRIA LTDA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 559,
                        "razaosocial": "Casa & Vídeo",
                        "cnpj": "",
                        "nomefantasia": "Casa & Vídeo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 560,
                        "razaosocial": "Celanese",
                        "cnpj": "",
                        "nomefantasia": "Celanese (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 561,
                        "razaosocial": "CEPHEID",
                        "cnpj": "",
                        "nomefantasia": "CEPHEID (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 562,
                        "razaosocial": "Cervejaria Premium Paulista",
                        "cnpj": "",
                        "nomefantasia": "Cervejaria Premium Paulista (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 563,
                        "razaosocial": "Chama",
                        "cnpj": "",
                        "nomefantasia": "Chama (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 564,
                        "razaosocial": "Chanel do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Chanel do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 565,
                        "razaosocial": "CI Intercambio",
                        "cnpj": "",
                        "nomefantasia": "CI Intercambio (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 566,
                        "razaosocial": "Clarion Events",
                        "cnpj": "",
                        "nomefantasia": "Clarion Events (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 567,
                        "razaosocial": "Clear Channel",
                        "cnpj": "",
                        "nomefantasia": "Clear Channel (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 568,
                        "razaosocial": "ClickBus",
                        "cnpj": "",
                        "nomefantasia": "ClickBus (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 569,
                        "razaosocial": "Colégio Loyola",
                        "cnpj": "",
                        "nomefantasia": "Colégio Loyola (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 570,
                        "razaosocial": "Colégio São Luís",
                        "cnpj": "",
                        "nomefantasia": "Colégio São Luís (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 571,
                        "razaosocial": "Combe",
                        "cnpj": "",
                        "nomefantasia": "Combe (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 572,
                        "razaosocial": "COMGAS",
                        "cnpj": "",
                        "nomefantasia": "COMGAS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 573,
                        "razaosocial": "Consulado Britânico",
                        "cnpj": "",
                        "nomefantasia": "Consulado Britânico (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 574,
                        "razaosocial": "Copagaz",
                        "cnpj": "",
                        "nomefantasia": "Copagaz (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 575,
                        "razaosocial": "COPERSUCAR S.A",
                        "cnpj": "",
                        "nomefantasia": "COPERSUCAR S.A. (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 576,
                        "razaosocial": "Cory Alimentos",
                        "cnpj": "",
                        "nomefantasia": "Cory Alimentos (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 577,
                        "razaosocial": "Covestro",
                        "cnpj": "",
                        "nomefantasia": "Covestro (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 578,
                        "razaosocial": "Credsystem",
                        "cnpj": "",
                        "nomefantasia": "Credsystem (Portal de Relatório)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 579,
                        "razaosocial": "CTC - Centro de Tecnologia Canavieira",
                        "cnpj": "",
                        "nomefantasia": "CTC - Centro de Tecnologia Canavieira (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 580,
                        "razaosocial": "CVC",
                        "cnpj": "",
                        "nomefantasia": "CVC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 581,
                        "razaosocial": "DAMM",
                        "cnpj": "",
                        "nomefantasia": "DAMM (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 582,
                        "razaosocial": "Danone/águas",
                        "cnpj": "",
                        "nomefantasia": "Danone/águas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 583,
                        "razaosocial": "Danone/Lácteos",
                        "cnpj": "",
                        "nomefantasia": "Danone/Lácteos (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 584,
                        "razaosocial": "Danone/nutricia",
                        "cnpj": "",
                        "nomefantasia": "Danone/nutricia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 585,
                        "razaosocial": "Davene",
                        "cnpj": "",
                        "nomefantasia": "Davene (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 586,
                        "razaosocial": "Dechra",
                        "cnpj": "",
                        "nomefantasia": "Dechra (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 587,
                        "razaosocial": "Dentsply Sirona",
                        "cnpj": "",
                        "nomefantasia": "Dentsply Sirona (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 588,
                        "razaosocial": "Digisystem",
                        "cnpj": "",
                        "nomefantasia": "Digisystem (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 589,
                        "razaosocial": "Discovery Network Brasil",
                        "cnpj": "",
                        "nomefantasia": "Discovery Network Brasil (Portal de Relatórios) ",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 590,
                        "razaosocial": "EATON",
                        "cnpj": "",
                        "nomefantasia": "EATON (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 591,
                        "razaosocial": "EDP",
                        "cnpj": "",
                        "nomefantasia": "EDP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 592,
                        "razaosocial": "ELOGROUP",
                        "cnpj": "",
                        "nomefantasia": "ELOGROUP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 593,
                        "razaosocial": "Ericsson",
                        "cnpj": "",
                        "nomefantasia": "Ericsson (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 594,
                        "razaosocial": "Escola Eleva",
                        "cnpj": "",
                        "nomefantasia": "Escola Eleva ( Portal de Relatórios )",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 595,
                        "razaosocial": "ESPAÇO LASER",
                        "cnpj": "",
                        "nomefantasia": "ESPAÇO LASER (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 596,
                        "razaosocial": "Estácio de Sá",
                        "cnpj": "",
                        "nomefantasia": "Estácio de Sá (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 597,
                        "razaosocial": "Excelsior Seguros",
                        "cnpj": "",
                        "nomefantasia": "Excelsior Seguros (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 598,
                        "razaosocial": "FENASBAC",
                        "cnpj": "",
                        "nomefantasia": "FENASBAC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 599,
                        "razaosocial": "Fette",
                        "cnpj": "",
                        "nomefantasia": "Fette (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 600,
                        "razaosocial": "FGV",
                        "cnpj": "",
                        "nomefantasia": "FGV (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 601,
                        "razaosocial": "FIEP",
                        "cnpj": "",
                        "nomefantasia": "FIEP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 602,
                        "razaosocial": "ForCasa (Portal de Relatórios)",
                        "cnpj": "",
                        "nomefantasia": "ForCasa (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 603,
                        "razaosocial": "Fujitsu General do Brasil LTDA",
                        "cnpj": "",
                        "nomefantasia": "Fujitsu General do Brasil LTDA. (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 604,
                        "razaosocial": "GBX Capital Participações Ltda",
                        "cnpj": "",
                        "nomefantasia": "GBX Capital Participações Ltda (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 605,
                        "razaosocial": "GI GROUP",
                        "cnpj": "",
                        "nomefantasia": "GI GROUP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 606,
                        "razaosocial": "Gic Brasil",
                        "cnpj": "",
                        "nomefantasia": "Gic Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 607,
                        "razaosocial": "GPS Pamcary",
                        "cnpj": "",
                        "nomefantasia": "GPS Pamcary (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 608,
                        "razaosocial": "Greenergy",
                        "cnpj": "",
                        "nomefantasia": "Greenergy (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 609,
                        "razaosocial": "Grupo ATMA",
                        "cnpj": "",
                        "nomefantasia": "Grupo ATMA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 610,
                        "razaosocial": "Grupo Bunzl",
                        "cnpj": "",
                        "nomefantasia": "Grupo Bunzl (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 611,
                        "razaosocial": "Grupo Card",
                        "cnpj": "",
                        "nomefantasia": "Grupo Card (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 612,
                        "razaosocial": "Grupo Cura ",
                        "cnpj": "",
                        "nomefantasia": "Grupo Cura (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 613,
                        "razaosocial": "Grupo Flytour",
                        "cnpj": "",
                        "nomefantasia": "Grupo Flytour (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 614,
                        "razaosocial": "Grupo Itabom",
                        "cnpj": "",
                        "nomefantasia": "Grupo Itabom (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 615,
                        "razaosocial": "Grupo OPTY",
                        "cnpj": "",
                        "nomefantasia": "Grupo OPTY (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 616,
                        "razaosocial": "HandMade Design",
                        "cnpj": "",
                        "nomefantasia": "HandMade Design (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 617,
                        "razaosocial": "Harsco",
                        "cnpj": "",
                        "nomefantasia": "Harsco (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 618,
                        "razaosocial": "Havana",
                        "cnpj": "",
                        "nomefantasia": "Havana (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 619,
                        "razaosocial": "Heineken",
                        "cnpj": "",
                        "nomefantasia": "Heineken (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 620,
                        "razaosocial": "Honda Automóveis do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Honda Automóveis do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 621,
                        "razaosocial": "Hospital São Camilo",
                        "cnpj": "",
                        "nomefantasia": "Hospital São Camilo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 622,
                        "razaosocial": "HOSPITAL SIRIO LIBANÊS",
                        "cnpj": "",
                        "nomefantasia": "HOSPITAL SIRIO LIBANÊS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 623,
                        "razaosocial": "HTB",
                        "cnpj": "",
                        "nomefantasia": "HTB (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 624,
                        "razaosocial": "Hugo Boss",
                        "cnpj": "",
                        "nomefantasia": "Hugo Boss (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 625,
                        "razaosocial": "Ibratin",
                        "cnpj": "",
                        "nomefantasia": "Ibratin (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 626,
                        "razaosocial": "IFF ESSENCIAS E FRAGANCIAS LTDA",
                        "cnpj": "",
                        "nomefantasia": "IFF ESSENCIAS E FRAGANCIAS LTDA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 627,
                        "razaosocial": "IMCD",
                        "cnpj": "",
                        "nomefantasia": "IMCD (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 628,
                        "razaosocial": "Inloco",
                        "cnpj": "",
                        "nomefantasia": "Inloco (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 629,
                        "razaosocial": "INOVA NESTLÉ",
                        "cnpj": "",
                        "nomefantasia": "INOVA NESTLÉ (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 630,
                        "razaosocial": "Instituto Alana",
                        "cnpj": "",
                        "nomefantasia": "Instituto Alana (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 631,
                        "razaosocial": "Instituto Ayrton Senna",
                        "cnpj": "",
                        "nomefantasia": "Instituto Ayrton Senna (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 632,
                        "razaosocial": "Instituto Inhotim",
                        "cnpj": "",
                        "nomefantasia": "Instituto Inhotim (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 633,
                        "razaosocial": "INTERTUG",
                        "cnpj": "",
                        "nomefantasia": "INTERTUG (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 634,
                        "razaosocial": "INTEVA",
                        "cnpj": "",
                        "nomefantasia": "INTEVA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 635,
                        "razaosocial": "Intralinks",
                        "cnpj": "",
                        "nomefantasia": "Intralinks (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 636,
                        "razaosocial": "INVEPAR",
                        "cnpj": "",
                        "nomefantasia": "INVEPAR (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 637,
                        "razaosocial": "IPE Mineração",
                        "cnpj": "",
                        "nomefantasia": "IPE Mineração (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 638,
                        "razaosocial": "ISDIN",
                        "cnpj": "",
                        "nomefantasia": "ISDIN (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 639,
                        "razaosocial": "Itafos",
                        "cnpj": "",
                        "nomefantasia": "Itafos (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 640,
                        "razaosocial": "Itau Projeto ITI",
                        "cnpj": "",
                        "nomefantasia": "Itau Projeto ITI (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 641,
                        "razaosocial": "Jotun Brasil",
                        "cnpj": "",
                        "nomefantasia": "Jotun Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 642,
                        "razaosocial": "Kenner",
                        "cnpj": "",
                        "nomefantasia": "Kenner (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 643,
                        "razaosocial": "Kinase",
                        "cnpj": "",
                        "nomefantasia": "Kinase (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 644,
                        "razaosocial": "Klabin S.A",
                        "cnpj": "",
                        "nomefantasia": "Klabin S.A (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 645,
                        "razaosocial": "Kluber Lubrication",
                        "cnpj": "",
                        "nomefantasia": "Kluber Lubrication (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 646,
                        "razaosocial": "Koelnmesse",
                        "cnpj": "",
                        "nomefantasia": "Koelnmesse (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 647,
                        "razaosocial": "KOMATSU",
                        "cnpj": "",
                        "nomefantasia": "KOMATSU (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 648,
                        "razaosocial": "Körber Medipak",
                        "cnpj": "",
                        "nomefantasia": "Körber Medipak (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 649,
                        "razaosocial": "Krona",
                        "cnpj": "",
                        "nomefantasia": "Krona (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 650,
                        "razaosocial": "KUKA",
                        "cnpj": "",
                        "nomefantasia": "KUKA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 651,
                        "razaosocial": "LCA",
                        "cnpj": "",
                        "nomefantasia": "LCA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 652,
                        "razaosocial": "LEADEC",
                        "cnpj": "",
                        "nomefantasia": "LEADEC ( Portal de Relatórios )",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 653,
                        "razaosocial": "Lemos Advocacia",
                        "cnpj": "",
                        "nomefantasia": "Lemos Advocacia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 654,
                        "razaosocial": "LIBBS",
                        "cnpj": "",
                        "nomefantasia": "LIBBS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 655,
                        "razaosocial": "Lightsource BP",
                        "cnpj": "",
                        "nomefantasia": "Lightsource BP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 656,
                        "razaosocial": "LIQUIGÁS",
                        "cnpj": "",
                        "nomefantasia": "LIQUIGÁS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 657,
                        "razaosocial": "Local RIO",
                        "cnpj": "",
                        "nomefantasia": "Local RIO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 658,
                        "razaosocial": "Localiza",
                        "cnpj": "",
                        "nomefantasia": "Localiza (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 659,
                        "razaosocial": "Loggi",
                        "cnpj": "",
                        "nomefantasia": "Loggi (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 660,
                        "razaosocial": "LUNDIN MINUNG",
                        "cnpj": "",
                        "nomefantasia": "LUNDIN MINUNG (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 661,
                        "razaosocial": "MACHADO MEYER",
                        "cnpj": "",
                        "nomefantasia": "MACHADO MEYER (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 662,
                        "razaosocial": "MACMILLAN EDUCATION",
                        "cnpj": "",
                        "nomefantasia": "MACMILLAN EDUCATION (Portal de relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 663,
                        "razaosocial": "Magna",
                        "cnpj": "",
                        "nomefantasia": "Magna (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 664,
                        "razaosocial": "Makro",
                        "cnpj": "",
                        "nomefantasia": "Makro (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 665,
                        "razaosocial": "Makro Group",
                        "cnpj": "",
                        "nomefantasia": "Makro Group (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 666,
                        "razaosocial": "MAQUIRA",
                        "cnpj": "",
                        "nomefantasia": "MAQUIRA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 667,
                        "razaosocial": "MARCOMAR",
                        "cnpj": "",
                        "nomefantasia": "MARCOMAR ( Portal de Relatórios )",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 668,
                        "razaosocial": "Marista Sul",
                        "cnpj": "",
                        "nomefantasia": "Marista Sul (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 669,
                        "razaosocial": "Mary Kay",
                        "cnpj": "",
                        "nomefantasia": "Mary Kay (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 670,
                        "razaosocial": "MATTOS FILHO",
                        "cnpj": "",
                        "nomefantasia": "MATTOS FILHO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 671,
                        "razaosocial": "MAZARS",
                        "cnpj": "",
                        "nomefantasia": "MAZARS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 672,
                        "razaosocial": "Meat Snack Partners",
                        "cnpj": "",
                        "nomefantasia": "Meat Snack Partners (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 673,
                        "razaosocial": "Minutrade",
                        "cnpj": "",
                        "nomefantasia": "Minutrade (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 674,
                        "razaosocial": "Miriam Minas",
                        "cnpj": "",
                        "nomefantasia": "Miriam Minas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 675,
                        "razaosocial": "Mitsubishi Eletric do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Mitsubishi Eletric do Brasil (Portal de Relatório)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 676,
                        "razaosocial": "MOAGEIRA CEREAIS",
                        "cnpj": "",
                        "nomefantasia": "MOAGEIRA CEREAIS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 677,
                        "razaosocial": "Modec Serviços de Petróleo do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Modec Serviços de Petróleo do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 678,
                        "razaosocial": "Motul Brasil",
                        "cnpj": "",
                        "nomefantasia": "Motul Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 679,
                        "razaosocial": "MURAH",
                        "cnpj": "",
                        "nomefantasia": "MURAH (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 680,
                        "razaosocial": "NEC",
                        "cnpj": "",
                        "nomefantasia": "NEC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 681,
                        "razaosocial": "NEC LATIN AMERICA",
                        "cnpj": "",
                        "nomefantasia": "NEC LATIN AMERICA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 682,
                        "razaosocial": "NEOGRID",
                        "cnpj": "",
                        "nomefantasia": "NEOGRID (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 683,
                        "razaosocial": "Nestle Nespresso",
                        "cnpj": "",
                        "nomefantasia": "Nestle Nespresso (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 684,
                        "razaosocial": "Next Vision",
                        "cnpj": "",
                        "nomefantasia": "Next Vision (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 685,
                        "razaosocial": "Nortel - Sonepar",
                        "cnpj": "",
                        "nomefantasia": "Nortel - Sonepar (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 686,
                        "razaosocial": "Nova Transportadora do Sudeste",
                        "cnpj": "",
                        "nomefantasia": "Nova Transportadora do Sudeste (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 687,
                        "razaosocial": "Novo Mundo",
                        "cnpj": "",
                        "nomefantasia": "Novo Mundo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 688,
                        "razaosocial": "NSF Internacional",
                        "cnpj": "",
                        "nomefantasia": "NSF Internacional (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 689,
                        "razaosocial": "Nubank",
                        "cnpj": "",
                        "nomefantasia": "Nubank (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 690,
                        "razaosocial": "Oi",
                        "cnpj": "",
                        "nomefantasia": "Oi (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 691,
                        "razaosocial": "Omie",
                        "cnpj": "",
                        "nomefantasia": "Omie (Contato de Relatório)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 692,
                        "razaosocial": "OMRON",
                        "cnpj": "",
                        "nomefantasia": "OMRON. ( Portal de relatório ) ",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 693,
                        "razaosocial": "Open Labs",
                        "cnpj": "",
                        "nomefantasia": "Open Labs ( Portal de Relatórios )",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 694,
                        "razaosocial": "Peri Brasil",
                        "cnpj": "",
                        "nomefantasia": "Peri Brasil ( Portal de Relatórios )",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 695,
                        "razaosocial": "PERKINELMER",
                        "cnpj": "",
                        "nomefantasia": "PERKINELMER (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 696,
                        "razaosocial": "Plasac",
                        "cnpj": "",
                        "nomefantasia": "Plasac (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 697,
                        "razaosocial": "Plataformatec",
                        "cnpj": "",
                        "nomefantasia": "Plataformatec (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 698,
                        "razaosocial": "PLP - Energia",
                        "cnpj": "",
                        "nomefantasia": "PLP - Energia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 699,
                        "razaosocial": "Porto Sudeste do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Porto Sudeste do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 700,
                        "razaosocial": "PRÓ RH",
                        "cnpj": "",
                        "nomefantasia": "PRÓ RH (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 701,
                        "razaosocial": "Procter & Gamble do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Procter & Gamble do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 702,
                        "razaosocial": "PRODAP",
                        "cnpj": "",
                        "nomefantasia": "PRODAP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 703,
                        "razaosocial": "Promon Engenharia",
                        "cnpj": "",
                        "nomefantasia": "Promon Engenharia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 704,
                        "razaosocial": "Qsaúde",
                        "cnpj": "",
                        "nomefantasia": "Qsaúde (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 705,
                        "razaosocial": "Raízen",
                        "cnpj": "",
                        "nomefantasia": "Raízen (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 706,
                        "razaosocial": "RCL IMPLANTES",
                        "cnpj": "",
                        "nomefantasia": "RCL IMPLANTES (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 707,
                        "razaosocial": "Rio Bravo",
                        "cnpj": "",
                        "nomefantasia": "Rio Bravo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 708,
                        "razaosocial": "Rio Energy",
                        "cnpj": "",
                        "nomefantasia": "Rio Energy (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 709,
                        "razaosocial": "S2 Holding",
                        "cnpj": "",
                        "nomefantasia": "S2 Holding (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 710,
                        "razaosocial": "SAINT GOBAIN SEKURIT",
                        "cnpj": "",
                        "nomefantasia": "SAINT GOBAIN SEKURIT (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 711,
                        "razaosocial": "Saint-Gobain",
                        "cnpj": "",
                        "nomefantasia": "Saint-Gobain (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 712,
                        "razaosocial": "Samsung",
                        "cnpj": "",
                        "nomefantasia": "Samsung (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 713,
                        "razaosocial": "SANTOS BRASIL",
                        "cnpj": "",
                        "nomefantasia": "SANTOS BRASIL (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 714,
                        "razaosocial": "Saphyr Shopping Centers",
                        "cnpj": "",
                        "nomefantasia": "Saphyr Shopping Centers (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 715,
                        "razaosocial": "Sarstedt",
                        "cnpj": "",
                        "nomefantasia": "Sarstedt (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 716,
                        "razaosocial": "Schaeffler Brasil - Ina do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Schaeffler Brasil - Ina do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 717,
                        "razaosocial": "Shift",
                        "cnpj": "",
                        "nomefantasia": "Shift (Portal de Relatórios) ",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 718,
                        "razaosocial": "SHOPPING DO CIDADÃO",
                        "cnpj": "",
                        "nomefantasia": "SHOPPING DO CIDADÃO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 719,
                        "razaosocial": "Siano & Rêgo-Consultoria de Gestão de Empresas e Pessoas",
                        "cnpj": "",
                        "nomefantasia": "Siano & Rêgo-Consultoria de Gestão de Empresas e Pessoas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 720,
                        "razaosocial": "SIC",
                        "cnpj": "",
                        "nomefantasia": "SIC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 721,
                        "razaosocial": "SIMPRESS",
                        "cnpj": "",
                        "nomefantasia": "SIMPRESS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 722,
                        "razaosocial": "Sonepar",
                        "cnpj": "",
                        "nomefantasia": "Sonepar (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 723,
                        "razaosocial": "Souza Cruz",
                        "cnpj": "",
                        "nomefantasia": "Souza Cruz (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 724,
                        "razaosocial": "SP MODEC",
                        "cnpj": "",
                        "nomefantasia": "SP MODEC ( Portal de Relatórios )",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 725,
                        "razaosocial": "SUMITOMO",
                        "cnpj": "",
                        "nomefantasia": "SUMITOMO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 726,
                        "razaosocial": "TECNOGERA",
                        "cnpj": "",
                        "nomefantasia": "TECNOGERA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 727,
                        "razaosocial": "Tecnoperfil Alumínio",
                        "cnpj": "",
                        "nomefantasia": "Tecnoperfil Alumínio (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 728,
                        "razaosocial": "Telhanorte",
                        "cnpj": "",
                        "nomefantasia": "Telhanorte (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 729,
                        "razaosocial": "THALES GROUP",
                        "cnpj": "",
                        "nomefantasia": "THALES GROUP (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 730,
                        "razaosocial": "TIM",
                        "cnpj": "",
                        "nomefantasia": "Tim (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 731,
                        "razaosocial": "Trench, Rossi & Watanabe Advogados",
                        "cnpj": "",
                        "nomefantasia": "Trench, Rossi & Watanabe Advogados (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 732,
                        "razaosocial": "U-Shin",
                        "cnpj": "",
                        "nomefantasia": "U-Shin (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 733,
                        "razaosocial": "U.S. Pharmacopeia",
                        "cnpj": "",
                        "nomefantasia": "U.S. Pharmacopeia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 734,
                        "razaosocial": "Unaprosil",
                        "cnpj": "",
                        "nomefantasia": "Unaprosil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 735,
                        "razaosocial": "UNIMED FES",
                        "cnpj": "",
                        "nomefantasia": "UNIMED FES (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 736,
                        "razaosocial": "Unisys",
                        "cnpj": "",
                        "nomefantasia": "Unisys (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 737,
                        "razaosocial": "Vale S.A",
                        "cnpj": "",
                        "nomefantasia": "Vale S.A (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 738,
                        "razaosocial": "Versuvius",
                        "cnpj": "",
                        "nomefantasia": "Versuvius (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 739,
                        "razaosocial": "VLI",
                        "cnpj": "",
                        "nomefantasia": "VLI (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 740,
                        "razaosocial": "VOTORANTIM",
                        "cnpj": "",
                        "nomefantasia": "VOTORANTIM (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 741,
                        "razaosocial": "West Rock",
                        "cnpj": "",
                        "nomefantasia": "West Rock (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 742,
                        "razaosocial": "WILD LIFE",
                        "cnpj": "",
                        "nomefantasia": "WILD LIFE (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 743,
                        "razaosocial": "Wine",
                        "cnpj": "",
                        "nomefantasia": "Wine (Portal de Relatório)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 744,
                        "razaosocial": "Xerox",
                        "cnpj": "",
                        "nomefantasia": "Xerox (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 745,
                        "razaosocial": "Yara",
                        "cnpj": "",
                        "nomefantasia": "Yara ( Portal de Relatórios )",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 746,
                        "razaosocial": "DROGARIA ARAUJO",
                        "cnpj": "",
                        "nomefantasia": "DROGARIA ARAUJO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 747,
                        "razaosocial": "Tecon Suape S/A",
                        "cnpj": "",
                        "nomefantasia": "Tecon Suape S/A (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 748,
                        "razaosocial": "G4S Justice Services LLC",
                        "cnpj": "",
                        "nomefantasia": "G4S Justice Services LLC (Portal Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 749,
                        "razaosocial": "Mapfre",
                        "cnpj": "",
                        "nomefantasia": "Mapfre (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 750,
                        "razaosocial": "GranBio",
                        "cnpj": "",
                        "nomefantasia": "GranBio (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 751,
                        "razaosocial": "1 Telecom",
                        "cnpj": "",
                        "nomefantasia": "1 Telecom (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 752,
                        "razaosocial": "Econocom",
                        "cnpj": "",
                        "nomefantasia": "Econocom (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 753,
                        "razaosocial": "Via Varejo GPA",
                        "cnpj": "",
                        "nomefantasia": "Via Varejo GPA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 754,
                        "razaosocial": "Grupo Hygea Saúde",
                        "cnpj": "",
                        "nomefantasia": "Grupo Hygea Saúde (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 755,
                        "razaosocial": "Bem Brasil Alimentos",
                        "cnpj": "",
                        "nomefantasia": "Bem Brasil Alimentos (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 756,
                        "razaosocial": "Grupo JCPM",
                        "cnpj": "",
                        "nomefantasia": "Grupo JCPM (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 757,
                        "razaosocial": "Aptiv",
                        "cnpj": "",
                        "nomefantasia": "Aptiv (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 758,
                        "razaosocial": "G4S Brasil",
                        "cnpj": "",
                        "nomefantasia": "G4S Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 759,
                        "razaosocial": "Lundin Mining",
                        "cnpj": "",
                        "nomefantasia": "Lundin Mining (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 760,
                        "razaosocial": "Amphenol TFC",
                        "cnpj": "",
                        "nomefantasia": "Amphenol TFC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 761,
                        "razaosocial": "Acipi",
                        "cnpj": "",
                        "nomefantasia": "Acipi (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 762,
                        "razaosocial": "IT Mídia",
                        "cnpj": "",
                        "nomefantasia": "IT Mídia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 763,
                        "razaosocial": "Amvian Indústria e Comércio de Peças Automotivas",
                        "cnpj": "",
                        "nomefantasia": "Amvian Indústria e Comércio de Peças Automotivas (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 764,
                        "razaosocial": "FROHLICH",
                        "cnpj": "",
                        "nomefantasia": "FROHLICH (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 765,
                        "razaosocial": "ScanSource",
                        "cnpj": "",
                        "nomefantasia": "ScanSource (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 766,
                        "razaosocial": "Cirion Technologies",
                        "cnpj": "",
                        "nomefantasia": "Cirion Technologies (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 767,
                        "razaosocial": "Spirax Sarco Inc",
                        "cnpj": "",
                        "nomefantasia": "Spirax Sarco Inc (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 768,
                        "razaosocial": "FMU",
                        "cnpj": "",
                        "nomefantasia": "FMU (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 769,
                        "razaosocial": "SMZTO",
                        "cnpj": "",
                        "nomefantasia": "SMZTO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 770,
                        "razaosocial": "Petroreconcavo SA",
                        "cnpj": "",
                        "nomefantasia": "Petroreconcavo SA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 771,
                        "razaosocial": "Whatnot LTD",
                        "cnpj": "",
                        "nomefantasia": "Whatnot LTD (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 772,
                        "razaosocial": "Conexa Saúde",
                        "cnpj": "",
                        "nomefantasia": "Conexa Saúde (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 773,
                        "razaosocial": "Assa Abloy",
                        "cnpj": "",
                        "nomefantasia": "Assa Abloy (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 774,
                        "razaosocial": "B Braun",
                        "cnpj": "",
                        "nomefantasia": "B Braun (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 775,
                        "razaosocial": "Yamaha",
                        "cnpj": "",
                        "nomefantasia": "Yamaha (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 776,
                        "razaosocial": "Grupo Moura",
                        "cnpj": "",
                        "nomefantasia": "Grupo Moura (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 777,
                        "razaosocial": "Everis",
                        "cnpj": "",
                        "nomefantasia": "Everis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 778,
                        "razaosocial": "Cervejaria Petropolis",
                        "cnpj": "",
                        "nomefantasia": "Cervejaria Petropolis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 780,
                        "razaosocial": "G&P Projetos e Sistemas S.A",
                        "cnpj": "",
                        "nomefantasia": "G&P Projetos e Sistemas S.A (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 781,
                        "razaosocial": "HempMeds Brasil",
                        "cnpj": "",
                        "nomefantasia": "HempMeds Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 782,
                        "razaosocial": "Sixbell Brasil",
                        "cnpj": "",
                        "nomefantasia": "Sixbell Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 783,
                        "razaosocial": "KWS",
                        "cnpj": "",
                        "nomefantasia": "KWS (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 784,
                        "razaosocial": "Anthology",
                        "cnpj": "",
                        "nomefantasia": "Anthology (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 785,
                        "razaosocial": "FCA Group",
                        "cnpj": "",
                        "nomefantasia": "FCA Group (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 786,
                        "razaosocial": "Code Climate INC",
                        "cnpj": "",
                        "nomefantasia": "Code Climate INC (Portal de Relatórios) ",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 787,
                        "razaosocial": "Sylvamo",
                        "cnpj": "",
                        "nomefantasia": "Sylvamo (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 788,
                        "razaosocial": "LAVORO AGRO",
                        "cnpj": "",
                        "nomefantasia": "LAVORO AGRO (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 789,
                        "razaosocial": "Epic Games",
                        "cnpj": "",
                        "nomefantasia": "Epic Games (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 790,
                        "razaosocial": "Raízen Simplify",
                        "cnpj": "",
                        "nomefantasia": "Raízen Simplify (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 791,
                        "razaosocial": "Remessa Online",
                        "cnpj": "",
                        "nomefantasia": "Remessa Online (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 792,
                        "razaosocial": "Paytech Holding",
                        "cnpj": "",
                        "nomefantasia": "Paytech Holding (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 793,
                        "razaosocial": "Shinagawa",
                        "cnpj": "",
                        "nomefantasia": "Shinagawa (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 794,
                        "razaosocial": "Grupo Petrópolis",
                        "cnpj": "",
                        "nomefantasia": "Grupo Petrópolis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 795,
                        "razaosocial": "ARRAY STI NORLAND BRASIL",
                        "cnpj": "",
                        "nomefantasia": "ARRAY STI NORLAND BRASIL (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 796,
                        "razaosocial": "Interfood",
                        "cnpj": "",
                        "nomefantasia": "Interfood (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 797,
                        "razaosocial": "e-Core",
                        "cnpj": "",
                        "nomefantasia": "e-Core (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 798,
                        "razaosocial": "Eurochem",
                        "cnpj": "",
                        "nomefantasia": "Eurochem (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 799,
                        "razaosocial": "Techbiz",
                        "cnpj": "",
                        "nomefantasia": "Techbiz (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 800,
                        "razaosocial": "Hm Engenharia E Construcoes Sa",
                        "cnpj": "",
                        "nomefantasia": "Hm Engenharia E Construcoes Sa (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 801,
                        "razaosocial": "Construtora Sudoeste",
                        "cnpj": "",
                        "nomefantasia": "Construtora Sudoeste (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 802,
                        "razaosocial": "Cia. Hering",
                        "cnpj": "",
                        "nomefantasia": "Cia. Hering (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 803,
                        "razaosocial": "Portobello",
                        "cnpj": "",
                        "nomefantasia": "Portobello (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 804,
                        "razaosocial": "Tahto",
                        "cnpj": "",
                        "nomefantasia": "Tahto (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 805,
                        "razaosocial": "ApisFlora",
                        "cnpj": "",
                        "nomefantasia": "ApisFlora (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 806,
                        "razaosocial": "Voke - Notebooks e Desktops",
                        "cnpj": "",
                        "nomefantasia": "Voke - Notebooks e Desktops (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 807,
                        "razaosocial": "Victaulic",
                        "cnpj": "",
                        "nomefantasia": "Victaulic (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 808,
                        "razaosocial": "Essity do Brasil",
                        "cnpj": "",
                        "nomefantasia": "Essity do Brasil (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 809,
                        "razaosocial": "Fleetcor",
                        "cnpj": "",
                        "nomefantasia": "Fleetcor (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 810,
                        "razaosocial": "Shutterstock",
                        "cnpj": "",
                        "nomefantasia": "Shutterstock (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 811,
                        "razaosocial": "Dotz",
                        "cnpj": "",
                        "nomefantasia": "Dotz (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 812,
                        "razaosocial": "Hexágono Mining Inc",
                        "cnpj": "",
                        "nomefantasia": "Hexágono Mining Inc (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 813,
                        "razaosocial": "Elementis Global",
                        "cnpj": "",
                        "nomefantasia": "Elementis Global (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 814,
                        "razaosocial": "Logixboard",
                        "cnpj": "",
                        "nomefantasia": "Logixboard (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 815,
                        "razaosocial": "Animativa",
                        "cnpj": "",
                        "nomefantasia": "Animativa (SD INOVACOES)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 816,
                        "razaosocial": "Grupo São Martinho",
                        "cnpj": "",
                        "nomefantasia": "Grupo São Martinho (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 817,
                        "razaosocial": "AXENYA",
                        "cnpj": "",
                        "nomefantasia": "AXENYA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 818,
                        "razaosocial": "AleSat Combustíveis",
                        "cnpj": "",
                        "nomefantasia": "AleSat Combustíveis (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 819,
                        "razaosocial": "Envu",
                        "cnpj": "",
                        "nomefantasia": "Envu (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 820,
                        "razaosocial": "Mitre Realty",
                        "cnpj": "",
                        "nomefantasia": "Mitre Realty (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 821,
                        "razaosocial": "SPT",
                        "cnpj": "",
                        "nomefantasia": "SPT (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 822,
                        "razaosocial": "Austa Hospital",
                        "cnpj": "",
                        "nomefantasia": "Austa Hospital (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 823,
                        "razaosocial": "Scuola Italiana",
                        "cnpj": "",
                        "nomefantasia": "Scuola Italiana (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 824,
                        "razaosocial": "Cooxupé",
                        "cnpj": "",
                        "nomefantasia": "Cooxupé (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 825,
                        "razaosocial": "Algar",
                        "cnpj": "",
                        "nomefantasia": "Algar (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 826,
                        "razaosocial": "Santista",
                        "cnpj": "",
                        "nomefantasia": "Santista (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 827,
                        "razaosocial": "HONDA TRADING",
                        "cnpj": "",
                        "nomefantasia": "HONDA TRADING (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 828,
                        "razaosocial": "SESI SENAI",
                        "cnpj": "",
                        "nomefantasia": "SESI SENAI (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 829,
                        "razaosocial": "Prime Consultoria Imobiliária",
                        "cnpj": "",
                        "nomefantasia": "Prime Consultoria Imobiliária (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 830,
                        "razaosocial": "FertBela",
                        "cnpj": "",
                        "nomefantasia": "FertBela (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 831,
                        "razaosocial": "Grupo Saint Gobain",
                        "cnpj": "",
                        "nomefantasia": "Grupo Saint Gobain (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 832,
                        "razaosocial": "Blau farmaceutica",
                        "cnpj": "",
                        "nomefantasia": "Blau farmaceutica (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 833,
                        "razaosocial": "Geenpeace",
                        "cnpj": "",
                        "nomefantasia": "Geenpeace (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 834,
                        "razaosocial": "Tupinambá Mobilidade Elétrica",
                        "cnpj": "",
                        "nomefantasia": "Tupinambá Mobilidade Elétrica (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 835,
                        "razaosocial": "Datamine",
                        "cnpj": "",
                        "nomefantasia": "Datamine (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 836,
                        "razaosocial": "Imedato Nextay",
                        "cnpj": "",
                        "nomefantasia": "Imedato Nextay (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 837,
                        "razaosocial": "Paxos Technology Solutions",
                        "cnpj": "",
                        "nomefantasia": "Paxos Technology Solutions (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 838,
                        "razaosocial": "Grupo Ultra",
                        "cnpj": "",
                        "nomefantasia": "Grupo Ultra (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 839,
                        "razaosocial": "GSC",
                        "cnpj": "",
                        "nomefantasia": "GSC (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 840,
                        "razaosocial": "JLL",
                        "cnpj": "",
                        "nomefantasia": "JLL (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 841,
                        "razaosocial": "Aspen Techonology",
                        "cnpj": "",
                        "nomefantasia": "Aspen Techonology (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 842,
                        "razaosocial": "ARKEMA",
                        "cnpj": "",
                        "nomefantasia": "ARKEMA (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 843,
                        "razaosocial": "Sauter Digital",
                        "cnpj": "",
                        "nomefantasia": "Sauter Digital (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 844,
                        "razaosocial": "Liotecnica",
                        "cnpj": "",
                        "nomefantasia": "Liotecnica (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 845,
                        "razaosocial": "ACP Bioenergia",
                        "cnpj": "",
                        "nomefantasia": "ACP Bioenergia (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 846,
                        "razaosocial": "CBMM",
                        "cnpj": "",
                        "nomefantasia": "CBMM (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 847,
                        "razaosocial": "GRUPO PRINER",
                        "cnpj": "",
                        "nomefantasia": "GRUPO PRINER (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 848,
                        "razaosocial": "OCRIM",
                        "cnpj": "",
                        "nomefantasia": "OCRIM (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 849,
                        "razaosocial": "GOODSTORAGE",
                        "cnpj": "",
                        "nomefantasia": "GOODSTORAGE (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 850,
                        "razaosocial": "Worley",
                        "cnpj": "",
                        "nomefantasia": "Worley (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 851,
                        "razaosocial": "IHS Towers",
                        "cnpj": "",
                        "nomefantasia": "IHS Towers (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 852,
                        "razaosocial": "Drakar",
                        "cnpj": "",
                        "nomefantasia": "Drakar (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 853,
                        "razaosocial": "Nov",
                        "cnpj": "",
                        "nomefantasia": "Nov (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 854,
                        "razaosocial": "Necta Gás",
                        "cnpj": "",
                        "nomefantasia": "Necta Gás (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 855,
                        "razaosocial": "Grupo Moura",
                        "cnpj": "",
                        "nomefantasia": "Grupo Moura (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 856,
                        "razaosocial": "Oswaldo Cruz Química",
                        "cnpj": "",
                        "nomefantasia": "Oswaldo Cruz Química (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 857,
                        "razaosocial": "ABEAR",
                        "cnpj": "",
                        "nomefantasia": "ABEAR (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 858,
                        "razaosocial": "Magnetron",
                        "cnpj": "",
                        "nomefantasia": "Magnetron (Portal de Relatório)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 859,
                        "razaosocial": "Naos",
                        "cnpj": "",
                        "nomefantasia": "Naos (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 860,
                        "razaosocial": "ESPRO - Ensino Social Profissionalizante",
                        "cnpj": "",
                        "nomefantasia": "ESPRO - Ensino Social Profissionalizante (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 1861,
                        "razaosocial": "BIONOW",
                        "cnpj": "",
                        "nomefantasia": "BIONOW (Portal de Relatórios)",
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 1862,
                        "razaosocial": "Chris cintos",
                        "cnpj": "17.928.366/0001-28",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    },
                    {
                        "id": 1864,
                        "razaosocial": "Casas Bahia",
                        "cnpj": "",
                        "nomefantasia": undefined,
                        "matriz": false,
                        "ativo": true
                    }
                ]);
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao buscar empresas');
            });
    };

    const getAreas = () => {
        areaActions
            .list()
            .then((resp: any) => {
                setAreasPosicao(resp);
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao buscar Areas Posição');
            });
    };

    const getNiveis = () => {
        nivelActions
            .list()
            .then((resp: any) => {
                setNiveisPosicao(resp);
            })
            .catch((e: any) => {
                toast.error('Falha ao buscar Níveis Posição');
            });
    };

    const getEscopos = () => {
        escopoActions
            .list()
            .then((resp: any) => {
                setEscoposPosicao(resp);
            })
            .catch((e: any) => {
                toast.error('Falha ao buscar Escopos de Responsabilidades');
            });
    };

    const atualizarFormulario = (result: any) => {
        setLoadingForm(true);

        const contHist = contatoHistoricoEmpresas.find((c) => c.id === result.id);
        if (contHist === undefined) return;

        setContatoHistoricoEmpresa(contHist);
        setValue(empresas.find((e) => e.id === contHist!.empresaId)!);

        const posicoesMapped = contHist.posicoes!.map((element: IContatoHistoricoEmpresaPosicao) => ({
            ...element,
            dataInicio: dayjs(element.dataInicio?.toString()),
            dataTermino: dayjs(element.dataTermino?.toString()),
        }));
        reset({ posicoes: posicoesMapped });
        openDrawerHistorico(true);
        setTimeout(() => {
            setLoadingForm(false);
        }, 1500);
    };

    const openDrawerHistorico = (result: any) => {
        setOpen(result);
        if (!result) resetValues();
    };

    const HandleApagar = (id: any) => {
        setLoading(true);
        contatoHistoricoEmpresaActions
            .delete(id)
            .then(() => {
                const sub = tableData.filter((f) => f.id !== id);
                setTableData(sub);
                const subContato = contatoHistoricoEmpresas.filter((f) => f.id !== id);
                setContatoHistoricoEmpresas(subContato);
                toast.success('Histórico removido com sucesso');
            })
            .catch((e: any) => toast.error('Falha ao remover histórico'))
            .finally(() => setLoading(false));
    };

    const AdicionarNaLista = () => {
        openDrawerHistorico(false);
    };

    const handleClose = () => {
        setDialogValue({
            razaosocial: '',
            cnpj: '',
        });
        setOpenEmpresaDialog(false);
    };

    const handleSubmitEmpresa = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!dialogValue.razaosocial) {
            toast.warn('Preencha todos os campos necessários');
            return;
        }
        const valueSubmit = {
            razaosocial: dialogValue.razaosocial,
            cnpj: dialogValue.cnpj ?? '',
        };
        empresaActions
            .create(valueSubmit)
            .then((data) => {
                setValue(data);
                toast.success('Empresa criada com sucesso');

                empresaActions
                    .list()
                    .then((resp: any) => {
                        setEmpresas(resp);
                        if (value) {
                            setValue(resp.find((e: any) => e.id == value));
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        toast.error('Falha ao buscar empresas');
                    });
            })
            .catch((e: any) => {
                console.error(e);
                toast.error('Falha ao salvar nova Empresa');
            });

        handleClose();
    };

    const filterOptions = useMemo(() => {
        return (options: IEmpresaType[], params: any) => {
            const filtered = options.filter((option) =>
                option.razaosocial.toLowerCase().includes(params.inputValue.toLowerCase())
            );
            if (params.inputValue !== '') {
                filtered.push({
                    inputValue: params.inputValue,
                    razaosocial: `Adicionar: "${params.inputValue}"`,
                    cnpj: '',
                });
            }
            return filtered;
        };
    }, [empresas]);

    return (
        <>
            <Grid xs={12} md={12} sx={{ padding: '20px 0px' }}>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Typography sx={{ padding: '10px' }} variant="h5" component="h2">
                        Histórico Profissional
                    </Typography>
                    <Button
                        style={{
                            padding: '0px',
                            height: '27px',
                            minWidth: '27px',
                            fontSize: '20px',
                            borderRadius: '50%',
                            marginTop: '10px',
                        }}
                        onClick={() => openDrawerHistorico(true)}
                        variant="contained"
                    >
                        +
                    </Button>
                </Grid>
                <Divider orientation="horizontal" />
            </Grid>

            <Grid container xs={12} md={12}>
                <Grid container xs={12} md={12} sx={{ padding: '10px', paddingBottom: '60px' }}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead sx={{ minWidth: 650, backgroundColor: '#0e95ba85' }}>
                                <TableRow>
                                    <TableCell>Empresa</TableCell>
                                    <TableCell>Último Cargo / Posição</TableCell>
                                    <TableCell>Data Início</TableCell>
                                    <TableCell>Data Fim</TableCell>
                                    <TableCell>Ações</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody sx={{ backgroundColor: '#0392ca24' }}>
                                {contatoHistoricoEmpresas.map((row: IContatoHistoricoEmpresa) => (
                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {row.empresaIdNavigation.razaosocial}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                // row.posicoes.length > 0 && areasPosicao.find((f) => f.id == row.posicoes[0].id! )?.nome
                                                // + ' - ' +
                                                niveisPosicao.find(
                                                    (f) =>
                                                        f.id ==
                                                        row.posicoes.find((f) => f.historicoEmpresaId == row.id)?.nivelId,
                                                )?.nome
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {row.posicoes.length > 0
                                                ? new Date(
                                                    row.posicoes
                                                        .find((f) => f.historicoEmpresaId == row.id)!
                                                        .dataInicio.toString(),
                                                ).toLocaleDateString()
                                                : ''}
                                        </TableCell>
                                        <TableCell>
                                            {row.posicoes.length > 0
                                                ? new Date(
                                                    row.posicoes
                                                        .find((f) => f.historicoEmpresaId == row.id)!
                                                        .dataTermino.toString(),
                                                ).toLocaleDateString()
                                                : ''}
                                        </TableCell>
                                        <TableCell>
                                            <Grid container xs={12} md={12}>
                                                <Grid container xs={6} md={6}>
                                                    <Icon sx={{ cursor: 'pointer' }} onClick={() => atualizarFormulario(row)}>
                                                        {' '}
                                                        <EditIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                                <Grid container xs={6} md={6}>
                                                    <Icon sx={{ cursor: 'pointer' }} onClick={() => HandleApagar(row.id!)}>
                                                        {' '}
                                                        <DeleteIcon />{' '}
                                                    </Icon>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {loadingGet && (
                        <Grid container xs={12} md={12} sm={12} sx={{ display: 'flex' }}>
                            <LinearProgress sx={{ width: '100%' }} />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Drawer
                style={{ minWidth: '450px !important' }}
                anchor={'right'}
                open={open}
                onClose={() => openDrawerHistorico(false)}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
                >
                    <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '450px' }}>
                        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: "25px" }}>
                            <Grid item xs={2} md={2}>
                                <IconDrawer onClick={() => openDrawerHistorico(false)}>close</IconDrawer>
                            </Grid>
                            <Grid item xs={10} md={10}>
                                <Typography variant="h5" sx={{ paddingTop: "5px" }}>Histórico Profissional</Typography>
                            </Grid>
                        </Grid>

                        <GridItemCadastro item xs={12} style={{ marginTop: '30px' }}>
                            <>
                                <Autocomplete
                                    value={value}
                                    disabled={(contatoHistoricoEmpresa?.id ?? 0) > 0}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            setTimeout(() => {
                                                setOpenEmpresaDialog(true);
                                                setDialogValue({
                                                    razaosocial: newValue,
                                                    cnpj: '',
                                                });
                                            });
                                        } else if (newValue && newValue.inputValue) {
                                            setOpenEmpresaDialog(true);
                                            setDialogValue({
                                                razaosocial: newValue.inputValue,
                                                cnpj: '',
                                            });
                                        } else {
                                            setValue(newValue);
                                        }
                                    }}
                                    filterOptions={filterOptions}
                                    id="adicionar-empresa-dialog"
                                    options={empresas}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.razaosocial;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(props, option) => {
                                        const { key, ...rest } = props;
                                        return <li key={option.id ?? `${option.razaosocial}-${Math.random()}`} {...rest}>{option.razaosocial}</li>;
                                    }}
                                    fullWidth
                                    freeSolo
                                    renderInput={(params) => <TextFieldStato {...params} label="Empresa Cliente" />}
                                />
                                <Dialog open={openEmpresaDialog} onClose={handleClose}>
                                    <form>
                                        <DialogTitle>Adicionar nova Empresa</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                Complete as informações da nova Empresa!
                                            </DialogContentText>
                                            <TextFieldStato
                                                autoFocus
                                                margin="dense"
                                                id="razaosocial"
                                                value={dialogValue.razaosocial}
                                                onChange={(event) =>
                                                    setDialogValue({
                                                        ...dialogValue,
                                                        razaosocial: event.target.value,
                                                    })
                                                }
                                                label="Razão Social"
                                                type="text"
                                            />
                                            <TextFieldStato
                                                margin="dense"
                                                id="cnpj"
                                                value={dialogValue.cnpj}
                                                onChange={(event) =>
                                                    setDialogValue({
                                                        ...dialogValue,
                                                        cnpj: event.target.value,
                                                    })
                                                }
                                                label="CNPJ"
                                                type="text"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <ButtonStato color="error" onClick={handleClose}>
                                                Cancelar
                                            </ButtonStato>
                                            <ButtonStato onClick={(e: any) => handleSubmitEmpresa(e)}>
                                                Adicionar
                                            </ButtonStato>
                                        </DialogActions>
                                    </form>
                                </Dialog>
                            </>
                        </GridItemCadastro>

                        {fields.map((field, index) => (
                            <>
                                <Accordion defaultExpanded key={index}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                        <Typography>Cargo - {index + 1}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <Controller
                                                name={`posicoes.${index}.areaId` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>Área</InputLabel>
                                                        <SelectStato {...field} label="Área">
                                                            {areasPosicao.map((area: IAreaPosicao) => (
                                                                <MenuItem value={area.id}>{area.nome}</MenuItem>
                                                            ))}
                                                        </SelectStato>
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.nivelId` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>Nível</InputLabel>
                                                        <SelectStato {...field} label="Nível">
                                                            {niveisPosicao.map((nivel: INivelPosicao) => (
                                                                <MenuItem value={nivel.id}>{nivel.nome}</MenuItem>
                                                            ))}
                                                        </SelectStato>
                                                    </FormControl>
                                                )}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.escopoId` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl fullWidth>
                                                        <InputLabel>Escopo</InputLabel>
                                                        <SelectStato {...field} label="Escopo">
                                                            {escoposPosicao.map((escopo: IEscopoResonsabilidade) => (
                                                                <MenuItem value={escopo.id}>{escopo.nome}</MenuItem>
                                                            ))}
                                                        </SelectStato>
                                                    </FormControl>
                                                )}
                                            />
                                            <TextFieldStato
                                                fullWidth
                                                label="Cargo"
                                                variant="outlined"
                                                {...register(`posicoes.${index}.cargo` as const)}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.cargoAtual` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={<Checkbox {...field} checked={field.value} />}
                                                        label="Cargo Atual"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name={`posicoes.${index}.dataInicio` as const}
                                                control={control}
                                                render={({ field }) => (
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                        localeText={{
                                                            clearButtonLabel: 'Empty',
                                                            todayButtonLabel: 'Now',
                                                        }}
                                                    >
                                                        <DesktopDatePicker
                                                            {...field}
                                                            value={dayjs(field.value)}
                                                            onChange={(value) => field.onChange(value!.toDate() as Date)}
                                                            label="Data Início"
                                                            format="DD/MM/YYYY"
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                            {!posicoesValue[index].cargoAtual &&
                                                <>
                                                    <Controller
                                                        name={`posicoes.${index}.dataTermino` as const}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <LocalizationProvider
                                                                dateAdapter={AdapterDayjs}
                                                                localeText={{
                                                                    clearButtonLabel: 'Empty',
                                                                    todayButtonLabel: 'Now',
                                                                }}
                                                            >
                                                                <DesktopDatePicker
                                                                    {...field}
                                                                    value={dayjs(field.value)}
                                                                    onChange={(value) => field.onChange(value!.toDate() as Date)}
                                                                    label="Data Término"
                                                                    format="DD/MM/YYYY"
                                                                />
                                                            </LocalizationProvider>
                                                        )}
                                                    />
                                                    <TextFieldStato
                                                        fullWidth
                                                        label="Superior (contato de referência)"
                                                        variant="outlined"
                                                        {...register(`posicoes.${index}.superior` as const)}
                                                    />
                                                    <TextFieldStato
                                                        fullWidth
                                                        label="Número de Subordinados"
                                                        variant="outlined"
                                                        type="number"
                                                        {...register(`posicoes.${index}.numeroDeSubordinados` as const)}
                                                    />
                                                    <TextFieldStato
                                                        fullWidth
                                                        label="Responsabilidades"
                                                        variant="outlined"
                                                        multiline
                                                        minRows={3}
                                                        {...register(`posicoes.${index}.responsabilidades` as const)}
                                                    />
                                                    <TextFieldStato
                                                        fullWidth
                                                        label="Realizações"
                                                        variant="outlined"
                                                        multiline
                                                        minRows={3}
                                                        {...register(`posicoes.${index}.realizacoes` as const)}
                                                    />
                                                    <TextFieldStato
                                                        fullWidth
                                                        label="Motivo da Saída"
                                                        variant="outlined"
                                                        multiline
                                                        {...register(`posicoes.${index}.motivoSaida` as const)}
                                                    />
                                                </>
                                            }
                                            <ButtonStato
                                                type="button"
                                                variant="contained"
                                                color="error"
                                                onClick={() => removerPosicao(index)}
                                            >
                                                Apagar
                                            </ButtonStato>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ))}

                        <ButtonStato type="button" onClick={() => append(emptyPosicao)} sx={{ mt: 2 }}>
                            Adicionar Nova Posição
                        </ButtonStato>
                        <ButtonStato type="submit" variant="contained" sx={{ mt: 2 }}>
                            Enviar
                        </ButtonStato>
                    </form>
                </Grid>
            </Drawer>
        </>
    );
};

export default HistoricoProfissionalComponent;
