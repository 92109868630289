import { AppBar, Box, Divider, Toolbar } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { useMenusActions } from '../../states/menus/menu.actions';
import Footer from '../Footer';
import Logo from './../../assets/STATO-INTOO-LOGO-MY-INTOO-VERS-2 A.png';
import MenuHorizontal from './Menu';
import MenuMobile from './MenuMobile';

interface LayoutProps {
  children: ReactNode;
}

export interface IMenuItens {
  nome: string; 
  link: string;
  filhos?: IMenuItens[];
}

const Header: React.FC<LayoutProps> = ({ children }) => {
  const [menuItens, setMenuItens] = useState<IMenuItens[]>([]);
  const menusActions = useMenusActions();
  const history = useHistory();

  useEffect(() => {
    menusActions
      .get()
      .then((d: any) => {
        const { areas, secoes } = d.resultado;
  
        function buildMenuStructure(areaId: number) {
          return areas
            .filter((area: any) => area.idParent === areaId)
            .map((area: any) => ({
              nome: area.nome,
              link: `/menus/${area.id}`,
              filhos: [
                ...buildMenuStructure(area.id),
                ...secoes
                  .filter((secao: any) => secao.idArea === area.id)
                  .map((secao: any) => ({
                    nome: secao.nome,
                    link: `/menus/${area.id}/secoes/${secao.id}`,
                  })),
              ],
            }));
        }
  
        const modulos = [
          {
            nome: 'Minha Transição',
            link: '/',
            filhos: [
              { nome: 'Ações', link: '/minha-transicao/acoes' },
              { nome: 'Dados Pessoais', link: '/minha-transicao/dados-pessoais' },
              { nome: 'Programa', link: '/minha-transicao/programa' },
            ],
          },
          ...areas
            .filter((area: any) => area.idParent === null)
            .filter((area: any) => area.nome !== 'Minha Transição')
            .map((area: any) => ({
              nome: area.nome,
              link: `/menus/${area.id}`,
              filhos: [
                ...buildMenuStructure(area.id),
                ...secoes
                  .filter((secao: any) => secao.idArea === area.id)
                  .map((secao: any) => ({
                    nome: secao.nome,
                    link: `/menus/${area.id}/secoes/${secao.id}`,
                  })),
              ],
            })),
        ];
  
        setMenuItens(modulos);
        localStorage.setItem('menusDinamicos', JSON.stringify(modulos));
      })
      .catch((erro) => toast.error(erro));
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        color="default"
        elevation={1}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            height: '100px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={Logo}
              alt="logo"
              width={'220px'}
              onClick={() => history.push('/')}
              style={{ cursor: 'pointer' }}
            />
          </Box>

          <DivMenuDesktop>
            <MenuHorizontal menuItens={menuItens} />
          </DivMenuDesktop>
          <DivMenuMobile>
            <MenuMobile menuItens={menuItens} />
          </DivMenuMobile>
        </Toolbar>
        <DividerMenu />
      </AppBar>

      <Box sx={{ marginTop: '104px', minHeight: 'calc(80vh)' }}>{children}</Box>

      <Footer />
    </>
  );
};

export const DividerMenu = styled(Divider)`
  height: 4px;
  width: 100%;
  max-width: 100vw;
  position: relative;
  background: linear-gradient(90deg, var(--blue-light) 0%, var(--green-light) 100%);
`;

const DivMenuDesktop = styled.div`
  @media (max-width: 576px) {
    display: none;
  }
`;

const DivMenuMobile = styled.div`
  @media (min-width: 577px) {
    display: none;
  }
`;

export default Header;
