import { useRecoilValue } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from 'styled-components';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { authAtom } from '../../states/usuarios/AuthState';
import { GridContainer } from '../../commons/styleds/styleds';
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Tab, Tabs } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useAcoesActions } from '../../states/acao/acao.actions';
import { IAcao } from '../../commons/types/crm';
import { IIdNome } from '../../commons/types/base';
import Breadcrumb from '../../components/Breadcrumb';
import ImgBackground from '../../assets/intoo_E.png';
import Consultor from '../Home/Components/Consultor';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const Acoes: React.FC = () => {
  const tableRef = useRef();
  const auth = useRecoilValue(authAtom);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [listTab, setListTab] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const acaoActions = useAcoesActions();
  const [acoes, setAcoes] = useState<IAcao[]>([]);
  const [configAcoes, setConfigAcoes] = useState<IIdNome[]>([]);

  const [oportunidadesIds, setOportunidadesIds] = useState<number[]>([]);
  const [reunioesIds, setReunioesIds] = useState<number[]>([]);
  const [empresasIds, setEmpresasIds] = useState<number[]>([]);
  const [indicacoesIds, setIndicacoesIds] = useState<number[]>([]);
  const [eventosIds, setEventosIds] = useState<number[]>([]);
  const [outrasAcoesIds, setOutrasAcoesIds] = useState<number[]>([]);
  const sobra = useState<number[]>([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    switch (newValue) {
      case 0:
        setLoading(true);
        setListTab(acoes.filter((f) => oportunidadesIds.indexOf(f.idTipo) >= 0));
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;
      case 1:
        setLoading(true);
        setListTab(acoes.filter((f) => reunioesIds.indexOf(f.idTipo) >= 0));
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;
      case 2:
        setLoading(true);
        setListTab(acoes.filter((f) => empresasIds.indexOf(f.idTipo) >= 0));
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;
      case 3:
        setLoading(true);
        setListTab(acoes.filter((f) => indicacoesIds.indexOf(f.idTipo) >= 0));
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;
      case 4:
        setLoading(true);
        setListTab(acoes.filter((f) => eventosIds.indexOf(f.idTipo) >= 0));
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;
      case 5:
        setLoading(true);
        setListTab(acoes.filter((f) => outrasAcoesIds.indexOf(f.idTipo) >= 0));
        setTimeout(() => {
          setLoading(false);
        }, 500);
        break;

      default:
        break;
    }
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  useEffect(() => {
    acaoActions.list(auth?.comanda?.id!).then((data: IAcao[]) => {
      setAcoes(data.filter((a) => a.habilitarAssessorado));

      acaoActions.listConfig().then((data: IIdNome[]) => {
        setConfigAcoes(data);
        setLoading(false);
      }).catch((e: any) => { toast.error(e); setLoading(false); });

    }).catch((e: any) => { toast.error(e); setLoading(false); });

  }, [auth]);

  useEffect(() => {
    setTimeout(() => {
      setListTab(acoes.filter((f) => f.idTipo == 2));
    }, 1000);

    setOportunidadesIds(
      configAcoes.filter((f) => f.nome == 'Candidatura no Banco de Oportunidades').map((m) => m.id),
    );

    setReunioesIds(
      configAcoes
        .filter(
          (f) =>
            f.nome == 'Criação de Lista de HeadHunters' ||
            f.nome == 'Criação de Lista de Empresas' ||
            f.nome.indexOf('Reunião') >= 0
        )
        .map((m) => m.id),
    );

    setIndicacoesIds(
      configAcoes
        .filter(
          (f) =>
            f.nome == 'Indicação p/ Vaga Anunciada' ||
            f.nome == 'Indicação p/ HeadHunter' ||
            f.nome == 'Indicação p/ Mercado-alvo',
        )
        .map((m) => m.id),
    );

    setEventosIds(configAcoes.filter((f) => f.nome == 'Participação em Evento').map((m) => m.id));

    const ids = configAcoes
      .filter(
        (f) =>
          f.nome == 'Candidatura no Banco de Oportunidades' ||
          f.nome == 'Criação de Lista de HeadHunters' ||
          f.nome == 'Criação de Lista de Empresas' ||
          f.nome == 'Indicação p/ Vaga Anunciada' ||
          f.nome == 'Indicação p/ HeadHunter' ||
          f.nome == 'Indicação p/ Mercado-alvo' ||
          f.nome == 'Indicação p/ Vaga Anunciada' ||
          f.nome == 'Participação em Evento' ||
          f.nome.indexOf('Reunião') >= 0
      )
      .map((m) => m.id);

    setOutrasAcoesIds(configAcoes.filter((f) => !(ids.indexOf(f.id) >= 0)).map((m) => m.id));
  }, [acoes, configAcoes]);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleCopy = () => {
    const tableElement = tableRef.current as any;
    if (!tableElement) return;

    let clipboardData = '';

    const headers: any[] = [];
    tableElement.querySelectorAll('thead tr th').forEach((th: any) => {
      headers.push(th.textContent);
    });
    clipboardData += headers.join('\t') + '\n';

    tableElement.querySelectorAll('tbody tr').forEach((row: any) => {
      const rowData: any[] = [];
      row.querySelectorAll('td').forEach((td: any) => {
        rowData.push(td.textContent);
      });
      clipboardData += rowData.join('\t') + '\n';
    });

    navigator.clipboard.writeText(clipboardData).then(
      () => {
        alert('Tabela copiada para o clipboard!');
      },
      (err) => {
        console.error('Erro ao copiar para o clipboard: ', err);
      },
    );
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: '#acoes-table' });
    doc.save('acoes.pdf');
  };

  const exportToXLS = () => {
    const tableElement = tableRef.current;
    if (tableElement) {
      const workbook = XLSX.utils.table_to_book(tableElement, { sheet: 'Sheet1' });
      XLSX.writeFile(workbook, 'tabela_exportada.xlsx');
    }
  };

  return (
    <GridBackground container direction="row" spacing={0}>
      <Consultor />

      <Grid item xs={12}>
        <Breadcrumb />
      </Grid>

      <Grid item xs={12} md={12} sx={{ paddingBottom: '30px' }}>
        <Typography variant="h1">Ações</Typography>
      </Grid>

      <Grid item xs={12} md={12} sx={{ paddingBottom: '10px' }}>
        <TypographyDesc variant="body1">
          Planejar e gerenciar suas atividades são ações fundamentais para aumentar a produtividade
          no seu processo de transição de carreira. As informações abaixo são relacionadas ao seu
          processo e tem como objetivo registrar indicações feitas pelo seu Consultor, suas
          candidaturas no banco de oportunidades e demais sites de vagas, bem como acompanhar os
          processos seletivos nos quais você está participando.
        </TypographyDesc>
      </Grid>

      <Grid item xs={12}>
        <PaperAcoes elevation={2}>
          <Box>
            <Box sx={{ paddingX: '24px' }}>
              <Tabs value={value} onChange={handleChange} aria-label="tabs acoes">
                <Tab label="Oportunidades" {...a11yProps(0)} />
                <Tab label="Reuniões" {...a11yProps(1)} />
                <Tab label="Lista de Empresa e HeadHunters" {...a11yProps(2)} />
                <Tab label="Indicações" {...a11yProps(3)} />
                <Tab label="Eventos" {...a11yProps(4)} />
                <Tab label="Outras Ações" {...a11yProps(5)} />
              </Tabs>
            </Box>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
                marginBottom: '20px',
                paddingRight: '24px',
                paddingLeft: '24px',
              }}
            >
              <div>
                <SearchInput
                  variant="outlined"
                  placeholder="Buscar"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ gap: '12px' }}>
                <ButtonAcao variant="outlined" onClick={handleCopy}>
                  Copiar o conteúdo
                </ButtonAcao>

                <ButtonAcao variant="outlined" onClick={exportToPDF} sx={{ marginLeft: '12px' }}>
                  <PictureAsPdfOutlinedIcon />
                  Exportar PDF
                </ButtonAcao>

                <ButtonAcao variant="outlined" onClick={exportToXLS} sx={{ marginLeft: '12px' }}>
                  <SummarizeOutlinedIcon />
                  Exportar XLS
                </ButtonAcao>
              </div>
            </div>

            {loading ? <LinearProgress sx={{ width: '100%' }} /> :
              <TabPanel value={0} index={0} dir={theme.direction}>
                <TableContainer component={Paper} ref={tableRef as any}>
                  <Table size="small" aria-label="a dense table" id="acoes-table">
                    <TableHead
                      sx={{
                        minWidth: 650,
                        backgroundColor: 'var(--white-background)',
                        paddingY: '12px',
                      }}
                    >
                      <TableRow>
                        <TableCell>Ação</TableCell>
                        <TableCell>Observação</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Anexo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listTab.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            minHeight: '72px',
                            height: '72px',
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell scope="row">
                            {configAcoes.find((f) => f.id == row.idTipo)?.nome}
                          </TableCell>
                          <TableCell>{row.observacoes}</TableCell>
                          <TableCell>
                            {row.dtPrevista &&
                              new Date(row.dtPrevista.toString()).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{row.anexo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            }
          </Box>
        </PaperAcoes>
      </Grid>
    </GridBackground>
  );
};

const ButtonAction = styled(Button)`
  background-color: #0091cf !important;
  color: white !important;
  border-radius: 10px !important;
`;

export const GridBackground = styled(GridContainer)`
  padding: 40px 32px 80px 32px;
  background-color: var(--gray-background);

  background-image: url(${ImgBackground});
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
`;

export const TypographyDesc = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  max-width: 800px;
`;

const PaperAcoes = styled(Paper)`
  padding-top: 24px;
  border-radius: 12px;
  background-color: #fff;
  width: 100%;
  margin-top: 56px;
`;

const SearchInput = styled(TextField)`
  height: 44px;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;

  > .MuiInputBase-adornedStart {
    height: 44px;
  }
`;

const ButtonAcao = styled(Button)`
  color: #414651 !important;
  border-color: #d5d7da !important;
  text-transform: none !important;
`;

export default Acoes;
