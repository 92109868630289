import { useBaseOneActions } from '../base.actionsOne';

function useEventosParticipantesActions() {
  const baseActions = useBaseOneActions('eventoParticipantes');

  return {
    get: baseActions.get,
    list: baseActions.list,
    create: baseActions.create,
    update: baseActions.update,
    delete: baseActions.delete,
  };
}

export { useEventosParticipantesActions };
