import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import { Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { styled } from 'styled-components';
import { OutlinedButton } from '../../../commons/styleds/buttons';
import { IComandaEtapaReunioes, IEtapa, StatusReuniaoEnum } from '../../../commons/types/crm';
import { authAtom } from '../../../states/usuarios/AuthState';
import { createHTMLFromString } from '../../PaginasDinamicas';
import EtapaCard from './EtapaCard';

const SuasEtapas: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const [etapaAtual, setEtapaAtual] = useState<IEtapa>();
  const [reuniaoAtual, setReuniaoAtual] = useState<IComandaEtapaReunioes>();
  const [ultimasEtapas, setUltimasEtapas] = useState<IEtapa[]>([]);
  const [proximasEtapas, setProximasEtapas] = useState<IEtapa[]>([]);

  useEffect(() => {
    getEtapaAtual();
    getUltimasEtapas();
    getProximasEtapas();
  }, []);

  // TODO: TEMOS QUE BUSCAR A CADA REFRESH E FAZER O CACHE NO BACK-END
  const getEtapaAtual = () => {
    const proximaReuniao = auth?.comanda?.comandaEtapaReunioes?.find(
      (cer) => cer.status == StatusReuniaoEnum.AGENDADA,
    );
    const etapa = auth?.comanda?.etapas?.find((e) => e.id == proximaReuniao?.tbEtapaId);
    setEtapaAtual(etapa);
    setReuniaoAtual(proximaReuniao);
  };

  const getUltimasEtapas = () => {
    const reunioesRealizadas = auth?.comanda?.comandaEtapaReunioes?.filter(
      (r) => r.status == StatusReuniaoEnum.APROVADA,
    );
    if (!reunioesRealizadas) return;

    const etapas = auth?.comanda?.etapas
      ?.filter((e) => !!reunioesRealizadas.find((r) => r.tbEtapaId == e.id))
      ?.slice(0, 4);
    if (!etapas) return;
    setUltimasEtapas(etapas);
  };

  const getProximasEtapas = () => {
    const etapas = auth?.comanda?.etapas
      ?.filter((e) => auth?.comanda?.comandaEtapaReunioes?.every((cer) => cer.tbEtapaId != e.id))
      ?.slice(0, 4);
    if (!etapas) return;
    setProximasEtapas(etapas);
  };

  const cardEtapaAtual = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Grid container spacing={3}>
        <Grid size={12}>
          <Typography variant="subtitle1">{etapaAtual?.nome}</Typography>
          <StatusEtapa>Agendada</StatusEtapa>
        </Grid>
      </Grid>

      <LinkEtapaAtual to="/minha-transicao/programa">
        Visualizar esta etapa <ArrowForwardRoundedIcon />
      </LinkEtapaAtual>
    </div>
  );

  const cardUltimasEtapas = () => (
    <div style={{ height: '100%', width: '100%' }}>
      <Divider />
      <Grid container spacing={1} sx={{ width: '100%', overflowY: 'hidden' }}>
        {ultimasEtapas?.map((e) => (
          <Grid size={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                gap: '24px',
                padding: '24px',
              }}
            >
              <TaskAltRoundedIcon color="success" />
              <Typography variant="body1">{e.nome}</Typography>
            </div>
            <Divider sx={{ width: '100%' }} />
          </Grid>
        ))}
      </Grid>
    </div>
  );

  const cardProximasEtapas = () => (
    <div style={{ height: '100%', width: '100%' }}>
      <Divider />
      <Grid container spacing={1} sx={{ width: '100%', overflowY: 'hidden' }}>
        {proximasEtapas?.map((e) => (
          <Grid size={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                gap: '24px',
                padding: '24px',
              }}
            >
              <AccessTimeRoundedIcon color="info" />
              <Typography variant="body1">{e.nome}</Typography>
            </div>
            <Divider sx={{ width: '100%' }} />
          </Grid>
        ))}
      </Grid>
    </div>
  );

  return (
    <SuasEtapasDiv>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography variant="h3">Suas Etapas</Typography>
            </div>
            <div>
              <OutlinedButton to="/minha-transicao/programa">Ir para o meu programa</OutlinedButton>
            </div>
          </div>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <EtapaCard headerTitle="Etapa atual">{cardEtapaAtual()}</EtapaCard>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <EtapaCard headerTitle="Últimas etapas">{cardUltimasEtapas()}</EtapaCard>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <EtapaCard headerTitle="Próximas etapas">{cardProximasEtapas()}</EtapaCard>
        </Grid>
      </Grid>
    </SuasEtapasDiv>
  );
};

const SuasEtapasDiv = styled.div`
  background: var(--white-background);
  height: 620px;
  padding: 48px 32px;
`;

const StatusEtapa = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;

  width: 80px;
  height: 22px;

  background: #fffaeb;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-size: 12px;
  line-height: 18px;

  color: #b54708;
`;

const LinkEtapaAtual = styled(Link)`
  color: var(--blue-medium);
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export default SuasEtapas;
